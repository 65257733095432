import React, {useContext, useEffect, useState} from "react";

import FormTextField from "components/ui/form/components/FormTextField";
import MyDatePicker from "components/ui/form/components/DatePicker";
import MultipleSelect from "components/ui/form/components/MultipleSelect";
import BasicSingleSelect from "components/ui/form/components/BasicSingleSelect";
import UserAccess from "context/permissions/UserAccess";

import { ButtonGroup } from "@mui/material";

import { Card, CardContent, CardActions, Box, FormGroup } from "@mui/material";
import { CardHeader, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { AdminContext } from "context/AdminContext";
import { PlayerContext } from "context/PlayerContext";
import { OrganizationContext } from "context/OrganizationContext";
import { MemberContext } from "context/MemberContext";
import { TeamContext } from "context/TeamContext";
import { YoutubeFrameGetter } from "components/ui/media/YoutubeFrameGetter";

import { newPlayerForm } from "./PlayerConfig";
import MyAccordion from "components/ui/accordion/MyAccordion";

const PlayerForm = (props) => {
  const { handleCloseForm,data, editing } = props
  const { currentOrg } = useContext(AdminContext)
  const { members } = useContext(MemberContext)
  const { organization } = useContext(OrganizationContext)
  const { team } = useContext(TeamContext)
  const { newPlayer, updatePlayer, playersLoading } = useContext(PlayerContext)    
  const [formData, setFormData] = useState()
  const [dob, setDOB] = useState()  
  const [selectedGuardians, setSelectedGuardias] = useState([])
  const [edit, setEdit] = useState(false)

  const handleForm = (e) => {  
    let target = e.target._id || e.target.name      
    setFormData({
      ...formData, 
      organization: currentOrg._id,     
      'dob': dob,      
      [target] : e.target.value
    })    
  }

  const handleWalkUp = (data) => {
    setFormData({
      ...formData,
      walkup: data
    })
  }

  const handleSubmit = async () => {    
    
    if (!editing) {                  
      newPlayer(formData)      
      handleCloseForm()
    }
    if (editing) {      
      updatePlayer(formData.id, formData)
      handleCloseForm()
    }    
  }    

  const guardians = {
    _id: 'guardians',
    label: 'Guardians',    
    options: members,
  }

  const organizationField = {
    _id: 'organization',
    label: 'Organization',    
    options: organization,
  }

  const teamField = {
    _id: 'currentTeam',
    label: 'Assign to Team',
    options: team
  }

  const disabled = (!formData?.firstName && !formData?.lastName && !formData?.dob)

  useEffect(() => {
    if (data && data.length) {      
      setFormData(data[0])
      let temp = []      
      data.forEach((item) => {
        item.guardians.forEach((person) => {          
          temp.push(person._id)
        })       
      })
      setEdit(false)      
      setSelectedGuardias(temp)
    }
  }, [data])

  useEffect(() => {
  if (!data) setEdit(true)
  }, [])
  
  
  return (<>
    
    <Card>          
      <CardActions>
        <ButtonGroup>
          <Button variant="outlined" onClick={() => handleCloseForm(false)}>Back</Button>
          {data && <Button variant='outlined' onClick={() => setEdit(!edit)}>{edit ? 'cancel': 'edit' }</Button>}
        </ButtonGroup>
      </CardActions>

      <CardHeader title={editing ? `${formData?.firstName} ${formData?.lastName}` : 'Register your players.'}/>

      <CardContent style={{display: 'block'}} >
        <FormGroup>
            <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}      
          >
          
          {newPlayerForm && newPlayerForm.map((field) => {
            if (field.type === 'date') {
              return (
                <MyDatePicker
                  key={field._id}
                  field={field}
                  handleDate={setDOB}
                  format={'MM/DD/YYYY'}
                  data={formData && formData.dob}
                  readOnly={!edit}
                />
              )
            } else if (field.type === 'text') {
              return (
                <FormTextField 
                key={field._id}
                field={field}
                handleForm={handleForm}
                data={formData && formData[field._id]}
                required={true}
                readOnly={!edit}
                />
              )
            }
          })}

          {members &&
            <MultipleSelect             
              field={guardians}
              multiple={true}
              data={selectedGuardians}
              state={[]}
              handleForm={handleForm}
              readOnly={!edit}
            />
          }



          {!editing && 
            <UserAccess restriction={'staff'}>
            {members &&
              <BasicSingleSelect             
                field={organizationField}
                multiple={false}
                //data={formData && formData.organization[0].id}
                data={organization[0]?._id}
                state={''}
                handleForm={handleForm}
                readOnly={!edit}
              />}
              </UserAccess>}

          </Box>
        </FormGroup>
      </CardContent>

      {edit && 
      <MyAccordion title={'Walk up Song'}>
        <YoutubeFrameGetter data={formData?.walkup} setWalkUp={handleWalkUp} />
      </MyAccordion>}

      <CardActions>
        <ButtonGroup>

          <LoadingButton
            loading={playersLoading}
            variant="outlined"
            onClick={handleSubmit}
            disabled={disabled || !edit}          
            >
            submit            
          </LoadingButton>         

          </ButtonGroup>
      </CardActions>      

    </Card>
  </>)
}

export default PlayerForm