import React, { useContext, useEffect, useState } from "react";

import {
  GridRowModes,
  DataGrid,  
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbarQuickFilter  
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import { Box } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

// Table Data
import { MemberContext } from "context/MemberContext";

import { userColumns } from "./UserTableConfig";

const UserMain = () => {
  const { members, memberLoading, updateUser } = useContext(MemberContext) 
  
  // Table
  const [columns, setColumns] = useState([]);      
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = React.useState({});

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {    
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => { 
    const id = newRow._id
    const body = newRow        
    updateUser(id, body)   
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));    
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setColumns(actions.concat(userColumns))
  }, [])

  useEffect(() => {
    setColumns(actions.concat(userColumns))
  }, [rowModesModel])

  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;        

        if (isInEditMode) {
          return [                       
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              className="textPrimary"
              onClick={handleSaveClick(id)}
              color="success"
          />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="info"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="info"
            onClick={handleEditClick(id)}            
          />,
        ];
      },
    },

  ]

  

  return (<>    
      <DataGrid 
        autoHeight
        rows={members}
        columns={columns}
        getRowId={(row) => row.id || row._id}
        editMode='row'   
        loading={memberLoading}
        rowModesModel={rowModesModel}  
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        pageSizeOptions={[5, 10, 50, 100]}
        initialState={{
          pagination: {paginationModel: {pageSize: 5}}
        }}  
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: QuickSearchToolbar

        }}          
      />
    </>
  )
}

export default UserMain