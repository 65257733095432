import React from "react";
import { Avatar, CardMedia, CardHeader, IconButton } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChatIcon from '@mui/icons-material/Chat';
import { ChatAvatarGroup } from "./ChatAvatarGroup";

export function ChatHeader(props) {
  const { room, action } = props;
  return (
    <React.Fragment>      
        <CardHeader 
          action={            
            <IconButton onClick={() => action(false)}>
              <KeyboardBackspaceIcon />
            </IconButton>               
          }             
          avatar={<Avatar src={`/images/${room.team.logo}`}/>}
          titleTypographyProps={{variant:'h6' }}        
          title={room.team.fullName}
          subheader={room.access !== 'user' ? 'Admin Room' : 'Team Room'}
        />      
    </React.Fragment> 
  )
}