import React, {useContext, useEffect, useState} from "react";
import { OrganizationContext } from "context/OrganizationContext";

import {Card, CardContent, Box, ButtonGroup, FormGroup, TextField, Stack, CardActions } from "@mui/material";

import { organizationForm, organizationContactForm } from "./OrganizationConfig";
import { CardHeader, Button, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const NewOrganizationForm = (props) => {
  const {newOrganization, editOrganization} = useContext(OrganizationContext)
  const { handler, data } = props;   

  // New form and default
  const [isNew, setIsNew] = useState(true)
  const [orgFormData, setOrgFormData] = useState()
  const [contactForm, setContactForm] = useState()
  const [error, setError] = useState(false)
  const [btnLoad, setBtnLoad] = useState(false)  
  // Editing
  const [editing, setEditing] = useState(false)

  const handleCloseForm = () => {
    handler(false)
  }

  const handleContactForm = (e) => {
    if (e.target.id === 'email' || e.target.id === 'tel') {
      setContactForm({
        ...contactForm, 
        [e.target.id]: {value : e.target.value}
      })
    } else {
      setContactForm({
        ...contactForm,      
        [e.target.id] : e.target.value,      
      })
    }
  }

  const handleOrgForm = (e) => {
    setOrgFormData({
      ...orgFormData,
      [e.target.id] : e.target.value
    })
    e.target.validity.valid ? 
    setError({...error, [e.target.id] : false}) :
    setError({...error, [e.target.id] : true})          
  }

  const submit = async () => {
    setBtnLoad(true)    
    const body = {org: orgFormData, contact: contactForm}
    newOrganization(body)    
    handler()
  }

  const submitEdit = async () => {
    setBtnLoad(true)        
    const body = {org: orgFormData, contact: contactForm}
    const id = orgFormData._id    
    editOrganization(id, body)    
    handleCloseForm()    
  }

  useEffect(() => { 
    if (data?.length) {
      setOrgFormData(data[0])
      setContactForm(data[0].contact)      
      setIsNew(false)
      setEditing(true)      
    }
  }, [data])

  return (    
    
        <Card>          
          <CardHeader title={orgFormData?.name || 'New Organization' } />        
          <CardActions>
            <ButtonGroup>
              <Button variant="outlined" onClick={handleCloseForm}>Back</Button>
              {!isNew && <Button variant="outlined" onClick={(() => setEditing(!editing))}>Edit</Button>}
            </ButtonGroup>
          </CardActions>

          <CardContent style={{display: 'block'}}>
            <FormGroup row validate='true'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}      
              >

                {organizationForm.map((field) => {                     
                    return (
                      <TextField          
                        sx={{m:2, ml:0, maxWidth: '500px' }}   
                        key={`${field.id}_key`}         
                        id={field.id}                      
                        type={field.type}
                        required      
                        value={orgFormData && orgFormData[field.id]}
                        label={field.type === 'file' ? '' : field.label}                                      
                        onChange={(e) => handleOrgForm(e)}                
                        error={error[field.id]}             
                        disabled={editing}
                        InputProps={{
                          readOnly: field.readOnly ? 'true' : false
                        }}
                        
                      > 
                      </TextField>                             
                    )
                })}  

                {organizationContactForm.map((field) => {                     
                  return (
                    <TextField          
                      sx={{m:2, ml:0, maxWidth: '500px' }}   
                      key={`${field.id}_key`}         
                      id={field.id}                      
                      type={field.type}
                      label={field.label}
                      disabled={editing}
                      error={error[field.id]}             
                      onChange={(e) => handleContactForm(e)}                
                      InputProps={{
                        readOnly: field.readOnly ? 'true' : false
                      }}
                      value={contactForm &&
                        (
                          (contactForm[field.id]?.value || '') ||
                          ( contactForm[field.id] || '' )
                        )}
                      required                              
                    > 
                    </TextField>                             
                  )
                })}  

              </Box>
            </FormGroup>
            <Alert severity="warning">Email must be different than user email.</Alert>
          </CardContent>

          <CardActions>
            <Stack spacing={2} direction="row">                
              <LoadingButton 
                variant="outlined"
                loading={btnLoad}
                disabled={!error}
                onClick={isNew ? submit : submitEdit}
              >
                Submit
              </LoadingButton>
              </Stack>
          </CardActions>
        </Card>    
  )
}

export default NewOrganizationForm