import React, { useContext, useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Stack, TextField, Alert, Box, Button, ButtonGroup, } from "@mui/material";

import VenueApi from "api/VenueApi";
import { VenueContext } from "context/VenueContext";
import GMap from "components/ui/map/GMap";

const VenueForm = (props) =>{
  const { data, handleCancel } = props
  const { getAllVenues } = useContext(VenueContext)
  const [isNew, setIsNew] = useState(true)
  const [editing, setEditing] = useState(isNew)
  const [formData, setFormData] = useState()
  const [map, setMap] = useState()
  const [awaiting, setAwaiting] = useState(false)

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.id] : e.target.value,      
    })    
  }

  const handleSetMap = (e) => {
    const regex = /https([^"]+)/g;
    let iframe = e.target.value
    let src = iframe.match(regex)
    if (src instanceof Array) {      
      setFormData({...formData, iframeUrl: src.toString()})
    }
  }

  const handleSubmit = () => {
    setAwaiting(true)
    VenueApi.create(formData)
    .then((res) => {
      if (res.status === 200) {
        getAllVenues()
      }
    }).finally(() => {
      setAwaiting(false)
      handleCancel()
    })
  }

  useEffect(() => {    
    if (data) {
      setIsNew(false)
      setFormData(data[0])        
    }
  }, [data])

  const disableSubmit = (!formData?.name || !formData?.address) || (editing && !isNew)

  return (
    <Card component='form'>
      <CardActions>
        <ButtonGroup>
          <Button variant="outlined" onClick={() => handleCancel(false)}>Back</Button>
          {!isNew && <Button variant="outlined" onClick={() => setEditing(!editing)}>{editing ? 'edit' : 'cancel edit' }</Button>}
        </ButtonGroup>
      </CardActions>
      <CardHeader title={isNew ? 'New Venue': formData.name}/>
      <CardContent style={{display: 'block'}}>          
        
        <Box            
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}      
          >

          <TextField 
            sx={{ m: 1, maxWidth: 500, width: '100%' }}
            variant="outlined"
            id='name'
            value={formData?.name || ''}
            label='Venue Name'
            onChange={(e) => {handleForm(e)}}
            disabled={editing && !isNew}
            required
          />
          <TextField 
            sx={{ m: 1, maxWidth: 500, width: '100%' }}
            variant="outlined"
            id='address'
            value={formData?.address || ''}
            label='Physical Address'
            onChange={(e) => {handleForm(e)}}
            disabled={editing && !isNew}
            required
          />
          {/* <TextField 
            sx={{ m: 1, maxWidth: 500, width: '100%' }}
            variant="outlined"
            id='iframeUrl'
            value={formData?.iframeUrl || ''}
            label='Paste Google Maps Iframe HTML'
            disabled={editing && !isNew}
            onChange={(e) => handleSetMap(e)}            
          /> */}

      {formData?.name && 
        <GMap place={`${formData.name}${formData.address}`} 
        />}

        </Box>                 
      </CardContent>

      {(isNew || !editing) && 
      <Alert severity="warning">Please ensure that you provide the required venue name and address, and verify the accuracy of the address you provide.</Alert>}     

      <CardActions>
        <Stack spacing={2} direction={'row'}>
          <ButtonGroup>
            <LoadingButton
              variant="outlined"              
              loading={awaiting}
              onClick={handleSubmit}
              disabled={disableSubmit}
            >Submit
            </LoadingButton>              
          </ButtonGroup>
        </Stack>
      </CardActions>


    </Card>
  )
}

export default VenueForm