import React, { useContext, useState } from "react";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { EventContext } from "context/EventContext";
import { Button, Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';

import LinearProgress from '@mui/material/LinearProgress';
import ScheduleForm from "./ScheduleForm";
import { eventColumns } from "./ScheduleConfig";

function EditToolbar(props) {
  const { handleOpenForm} = props;

  const handleClick = () => {
    handleOpenForm()
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        New Event
      </Button>
    </GridToolbarContainer>
  );
}

const ScheduleTable = () => {
  const { events, loading, setEvents, deleteEvent} = useContext(EventContext);  
  const [ formOpen, setFormOpen ] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState({});

  // Edit &  Delete
  const [editingRow, setEditingRow] = useState(false)
  const [columns, setColumns] = useState([])
  const [selected, setSelected] = useState(false)


  const handleOpenForm = () => {
    setFormOpen(!formOpen)
    setSelected(false)
  }    

  const clearSelected = () => {
    setSelected(false)
  }

  const handleEditClick = (id) => () => {   
    setEditingRow(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    setEditingRow(false)
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = events.find((row) => events.id === id);
    if (editedRow?.isNew) {
      setEvents(events.filter((row) => events.id !== id));
    }
  };

  const handleDeleteClick = (id) => async () => {    
    deleteEvent(id)
  };

  const onRowsSelectionHandler = (ids) => {    
    const selectedRowsData = ids.map((id) => events.find((row) => row._id === id));        
    setSelected(selectedRowsData)
  };

  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [            
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              title='Delete'              
              onClick={handleDeleteClick(id)}
              color='error'           
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="info"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="info"
            onClick={handleEditClick(id)}            
          />,
        ];
      },
    },

  ]

  React.useEffect(() => {
    setColumns(actions.concat(eventColumns))    
  }, [rowModesModel])


  React.useEffect(() => {
    setColumns(actions.concat(eventColumns))
  }, [])


  return (<>
    {!formOpen && !selected && 
    <DataGrid 
      autoHeight
      rows={events}
      columns={columns}
      loading={loading} 
      initialState={{
        pagination: {paginationModel: {pageSize: 5}}
      }}
      pageSizeOptions={[5, 10, 50, 100]}
      onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: EditToolbar
      }}
      slotProps={{
        toolbar: {handleOpenForm}        
      }}    
    />}

    {formOpen && <ScheduleForm handleOpenForm={handleOpenForm} /> }
    {selected && 
      <ScheduleForm
        handleOpenForm={clearSelected}
        data={selected} 
      /> }
    
    </>
    
  )
}

export default ScheduleTable