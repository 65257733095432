import api from "./_api";

class ViewApi {
  userTeams(id) {
    return api.get(`/user/data/${id}`)
  }
  updateUser(id, data) {
    return api.put(`/user/${id}`, data)
  }
  updateContact(id, data) {
    return api.put(`/user/contact/${id}`, data)
  }
  updatePlayer(id, data) {
    return api.put(`/user/player/${id}`, data)
  }
  newDevice(id, data) {
    return api.post(`/user/device/${id}`, data)
  }
}

export default new ViewApi();