import React, {useContext, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// Context
import { AdminContext } from 'context/AdminContext';
import { RegistrationProvider } from 'context/RegistrationContext';

import UserAccess from 'context/permissions/UserAccess';

// Toast
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

// Page Index
import ViewIndex from 'pages/view/ViewIndex';
import AdministrationIndex from 'pages/administration';

// Pages
import LoginPage from 'pages/auth/Login';
import RegistrationIndex from 'pages/auth/registration/RegistrationIndex';
import { ResetPassword } from 'pages/auth/ResetPassword';
import { ForgotPassword } from 'pages/auth/ForgotPassword';

import Loader from 'components/ui/backdrop/Loader';

function App() {     
  const { user } = useContext(AdminContext)            

  return (<>      
  {!user && 
  <RegistrationProvider>
    <Routes>
      <Route path="*" >

        <Route path='registration'>        
          <Route path=':organization/:token' element={<RegistrationIndex />} />
          <Route path=':organization/:token/:returning' element={<RegistrationIndex />} />
        </Route>

        <Route path="resetpassword/:token" element={<ResetPassword />} />    
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path='*' element={<LoginPage />} />        

      </Route>
    </Routes>
  </RegistrationProvider>}  

    {user && 
    <UserAccess restriction={'user'}>
      <Routes>
        <Route path='/*' element={<ViewIndex />}></Route>
        <Route path='/:organization/admin/*' element={<AdministrationIndex />} >
      </Route>        
      </Routes>
    </UserAccess>}


    <ToastContainer draggable stacked />
    <Loader />
  </>);
}

export default App;
