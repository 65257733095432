import React, {useState, createContext, useEffect, useContext} from "react";

import { ChatProvider } from "./ChatContext";
import { UserDeviceProvider } from "./UserDeviceContext";

import { AdminContext } from "./AdminContext";

import { setupNotifications } from "firebase";

import UserApi from "api/UserApi";


export const UserContext = createContext(false)

export const UserProvider = ({children}) => {  
  const { user } = useContext(AdminContext)
  const [teams, setTeams] = useState(false)
  const [events, setEvents] = useState([])
  const [chatRooms, setChatRooms] = useState()
  const [userLoading, setUserLoading] = useState(false)    

  const getInitialData = async () => {    
    setUserLoading(true)
    await UserApi.userTeams(user._id)
    .then((res) => {
      if (res.status === 200) {        
        setTeams(res.data.teams)
        setEvents(res.data.events)
        setChatRooms(res.data.rooms)
      }
    }).finally(() => {      
      setUserLoading(false)         
    })    
  }

  
  useEffect(() => {
    getInitialData()
    setupNotifications()    
  }, [])    


  

  const value={teams, events, userLoading, setEvents, getInitialData, chatRooms}

  return (
    <UserContext.Provider value={value}>
      <UserDeviceProvider>
        <ChatProvider>
          {children} 
        </ChatProvider>
      </UserDeviceProvider>
    </UserContext.Provider>
  )
}