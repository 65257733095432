import React, {useEffect, useContext, useState} from "react";

import {
  GridRowModes,
  DataGrid,  
  GridActionsCellItem,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridToolbarQuickFilter  
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { PlayerContext } from "context/PlayerContext";

import PlayerForm from "./form/PlayerForm";
import { playerColumns } from "./form/PlayerConfig";

const PlayerTable = () => {
  const { players, setPlayers, playersLoading } = useContext(PlayerContext)
  const [openForm, setOpenForm] = useState(false)
  const [selected, setSelected] = useState(false)

  const toolbarAction = () => {
    setOpenForm(!openForm)
  }  

  const handleSelection = (item) => {
    setSelected(item)
  }

  const [columns, setColumns] = useState([]);      
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = React.useState({});

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function EditToolbar(props) {
    const { toolbarAction } = props;
  
    const handleClick = () => {
      toolbarAction()
    };
  
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}    
        sx={{
          p: 0.5,
          pb: 0,
          justifyContent:'space-between'
        }}
      >
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          New Player
        </Button>
      </GridToolbarContainer>
      <GridToolbarQuickFilter />
    </Stack>
    );
  }  


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const onRowsSelectionHandler = (ids) => {    
    const selectedRowsData = ids.map((id) => players.find((row) => row._id === id));            
    setSelected(selectedRowsData)     
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {       
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));    
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setColumns(actions.concat(playerColumns))
  }, [])

  useEffect(() => {
    setColumns(actions.concat(playerColumns))
  }, [rowModesModel])


  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;               

        if (isInEditMode) {
          return [                       
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              onClick={handleDeleteClick(id)}
              color="error"
              disabled={true}
          />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="info"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="info"
            onClick={handleEditClick(id)}            
          />,
        ];
      },
    },
  ]


  return(<>

    
  {!openForm && !selected && 
    <DataGrid 
      autoHeight
      rows={players}
      columns={columns}
      getRowId={(row) => row.id || row._id}
      editMode='row'   
      loading={playersLoading}
      rowModesModel={rowModesModel}  
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)} 
      pageSizeOptions={[5, 10, 50, 100]}
      initialState={{
        pagination: {paginationModel: {pageSize: 5}}
      }}
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: EditToolbar
      }}          
      slotProps={{
        toolbar: {toolbarAction}        
      }}     

    />}
    {!openForm && selected && 
      <PlayerForm handleCloseForm={handleSelection} data={selected} editing={true}/>}
    {openForm && !selected && 
      <PlayerForm handleCloseForm={toolbarAction} />}
  </>)  
}

export default PlayerTable