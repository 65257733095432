import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { Link, CardHeader, Typography, CardContent, CardActions } from "@mui/material";

import { RegistrationContext } from "context/RegistrationContext";
import MyCard from "components/ui/card/MyCard";




const Finished = () => {
  const navigate = useNavigate()
  const { invitation } = useContext(RegistrationContext)
  
  return (
    <MyCard>
      <CardHeader
        sx={{textAlign: 'center'}}
        title={`Registration complete!`}         
        subheader={`Thank you from ${invitation.organization.name}`}
      />
      <CardContent>

        <Typography 
          align='center'
          variant="body1"
          >
          Please exit to use the app.</Typography>

      </CardContent>

      <CardActions>
     
          <Link
            underline="none"
            sx={{mt: 4, width: '95%', textAlign: 'center'}}
            component="button"
            variant="body2"
            onClick={() => {
              navigate('/')
            }}
            >
              Exit
            </Link>
      </CardActions>
        
        
      
      

    </MyCard>
  )
}

export default Finished