import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { UserContext } from "context/UserContext";

import TeamListItem from "./components/TeamListItem";
import TeamDetails from "./components/TeamDetails";


const TeamList = () => {
  const [teamsLoaded, setTeamsLoaded] = useState(false)
  const { teams } = useContext(UserContext)
  const [ selectedTeam, setSelectedTeam ] = useState(false)    

  const handleSelectedTeam = (data) => {
    setSelectedTeam(data)
  }

  useEffect(() => {
    if (teams.length > 0) setTeamsLoaded(true)
  },[teams])

  if (!teams.length > 0) return <>No teams available to view.</>
  
  if (teams.length > 0) return (<>      
    {teams && teamsLoaded && !selectedTeam &&
      <Stack 
        // direction={{ xs: 'column', sm: 'row'}}        
        direction={'column'}        
      >
        {teams.map((team) => {      
          return (
            <TeamListItem                 
              key={team._id} 
              team={team}
              handleSelectedTeam={handleSelectedTeam}
            />       
          )
        })}
      </Stack>}    

      {selectedTeam && 
        <TeamDetails 
          team={selectedTeam}
          handleSelectedTeam={handleSelectedTeam}           
        />}
  </>)
}

export default TeamList