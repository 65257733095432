import React, { useState } from "react";

import { CardContent, CardHeader, ListItem, Box, IconButton, Stack, Divider, Avatar  } from "@mui/material";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Slide from '@mui/material/Slide';

import { YoutubeFrame } from "components/ui/media/YoutubeFrame";


export function TeamStaff(props) {
  const { team } = props
  const [walkUp, setWalkUp] = useState(false)
  
  return (
    <CardContent sx={{p:0, pb:0}}>   
      <CardHeader subheader={'Staff'}   />
        {team?.teamManager?.user?.fullName && 
        <ListItem>
          <Avatar sx={{m:1}} src={team.teamManager.user.avatar}></Avatar>
          {team.teamManager.user.fullName} - Team Manager
        </ListItem>}          

        {team?.assistantManager?.user?.fullName &&
        <ListItem >
          <Avatar sx={{m:1}} src={team.assistantManager.user.avatar}></Avatar>
          {team.assistantManager.user.fullName} - Assistant Manager
        </ListItem>}           
      
      <CardHeader subheader={`Players (${team.roster.length})`} />
      <Box  
            sx={{
              display:{xs:"block", sm:'flex', mb:2},
              alignItems: 'flex-start'              
              }} 
            >

            <Box
              sx={{           
                alignSelf:'flex-start',                              
                position:{xs:'static', sm:'fixed', md:'fixed'},
                bottom: '50px',
                right:'20px',
                zIndex:99
            }}>

            {walkUp && <YoutubeFrame songID={walkUp}/>}
            </Box>

            <Stack direction={'column'} sx={{width: '100%'}}>

              {team?.roster && team.roster.map((roster) => {            
              return (
              <React.Fragment key={roster._id}>

                       
                <Stack direction='row' sx={{p:0, width: '100%', alignItems: 'center', color: roster.player.walkup === walkUp ? 'success' : ''   }}>
                  <Avatar sx={{m:1, backgroundColor: roster.player.walkup === walkUp ? 'success' : ''  }}>{roster.number}</Avatar>                
                    {roster.player.fullName} - {roster.positionPrimary}                  
                  {roster.player?.walkup &&
                  <IconButton                      
                    sx={{m:1, overflow:'hidden'}}                
                    title={"Load walk up song"}                  
                    disabled={roster.player.walkup === walkUp}
                    onClick={() => setWalkUp(roster.player.walkup)}
                    >
                    {roster.player.walkup === walkUp ? 
                    <Slide direction="right" in={roster.player.walkup === walkUp} mountOnEnter unmountOnExit>
                        <CheckBoxIcon                        
                          color='success'
                          /> 
                      </Slide> :                   
                      <LibraryMusicIcon  />}

                    </IconButton>}
                </Stack>                        
                  
                <Divider />
              </React.Fragment>)
              })}

            </Stack>
          </Box>

    </CardContent>
  )
}