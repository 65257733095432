import React, { createContext, useState, useEffect } from "react";

import MemberApi from "api/MemberApi";

export const MemberContext = createContext(false)
export const MemberProvider = ({children}) => {  
  const [memberLoading, setMemberLoading] = useState(false)
  const [ members, setMembers ] = useState([])

  const adminGetMembers = async () => {
    
   await MemberApi.get().then((res) => {    
    setMemberLoading(true)
    if (res.status === 200) {      
      setMembers(res.data.doc)
    }
   }).catch((err) => {
    console.log(err)
   }).finally(() => {
    setMemberLoading(false)
   })
  }

  const updateUser = async (id, data) => {
    setMemberLoading(true)
    await MemberApi.update(id, data)
    .then((res) => {
      if (res.status === 200) {        
        const newuser = res.data.data
        const updatedMemeber = members.map(item => item._id === newuser._id ? newuser : item);
        setMembers(updatedMemeber)
      }
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      setMemberLoading(false)
    })
  }

  
  useEffect(() => {
    adminGetMembers()       
  }, [])
    
  const value = {memberLoading, members, setMembers, updateUser}  
  
  return (
    <MemberContext.Provider value={value}>
      {children}
    </MemberContext.Provider>
  )

}

