import React, { useState, useContext, useEffect } from "react";

import { Alert, IconButton, CardActions, Card, CardHeader, CardContent, Box, Tabs, Tab, Stack, TextField } from '@mui/material'
import PropTypes from 'prop-types';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import { LoadingButton } from "@mui/lab";
import MyDatePicker from "components/ui/form/components/DatePicker";
import { YoutubeFrame } from "components/ui/media/YoutubeFrame";
import { YoutubeFrameGetter } from "components/ui/media/YoutubeFrameGetter";

import MyAccordion from "components/ui/accordion/MyAccordion";
import UserApi from "api/UserApi";
import { AdminContext } from "context/AdminContext";
import { UserContext } from "context/UserContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export function UserPlayer () {
  const { user, setUser } = useContext(AdminContext)
  const { getInitialData } = useContext(UserContext)
  const [loading, setLoading] = useState(false)

    // Form
    const [editForm, setEditForm] = useState(false)
    const [formData, setFormData] = useState(false)
    const [playerDob, setPlayerDob] = useState(user?.player?.dob)
  
    // Tabs
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);        
    };  
    // End Tabs

    const handleFormData = (e) => {
      setFormData({
        ...formData,
        email: user.contact.email.value,
        dob: playerDob,
        [e.target.id] : e.target.value
      })    
    }
  
    const setWalkUp = (data) => {
      setFormData({
        ...formData,
        walkup: data
      })    
    }
  
    const handleSubmit = async () => {
      setLoading(true)
      await UserApi.updatePlayer(formData._id, formData)
      .then((res) => {
        if (res.status === 200) {          
          const updatedPlayer = res.data.player;
          const newPlayers = user.player.map(player =>
            player.id === updatedPlayer.id ? updatedPlayer : player
          );
          setUser({
            ...user,
            player: newPlayers
          })
          getInitialData()
        }
      })    
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setEditForm(false)
        setLoading(false)
      })
    }
  
    useEffect(() => {
      setFormData(user?.player[value])        
    }, [value])
  
    useEffect(() => {    
      setFormData(user?.player[0])
    }, [user])
  

  return (
    <MyAccordion title='Player Information'>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="User Players"         
            variant="scrollable"
           >          
            {user?.player.map((play, i) => {
              return (
                <Tab label={play.fullName} key={i} {...a11yProps(i)} />
              )
            })}
          </Tabs>
        </Box>
      {user?.player.map((play, i) => {
        return (
        <CustomTabPanel value={value} index={i} key={i} >

          <IconButton 
            onClick={() => {setFormData(user?.player[value]); setEditForm(!editForm)}}             
          >
            { editForm ? <EditOffIcon /> : <EditIcon /> }
          </IconButton>

          
            
        <CardContent>                             

            <Box 
              component='form'             
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}>     
            
              <TextField 
                id='firstName'
                value={formData?.firstName || play.firstName}
                label={'First Name'}              
                variant={editForm ? 'outlined' : 'standard'}
                disabled={!editForm}
                onChange={(e) => handleFormData(e)}          
              />

              <TextField 
                id='lastName'
                value={formData?.lastName || ''}
                label={'Last Name'}                      
                variant={editForm ? 'outlined' : 'standard'}
                disabled={!editForm}
                onChange={(e) => handleFormData(e)}      
              />

              <MyDatePicker        
                field={{label: 'Date of Birth'}}                    
                format={'MM/DD/YYYY'}
                data={play.dob}
                readOnly={!editForm}
                handleDate={setPlayerDob}
              />            
            
              {editForm && <TextField 
                variant="outlined"
                label={'Confirm Password'}
                id={'password'}        
                type={'password'}
                onChange={(e) => handleFormData(e)}
                />}        

            </Box>
          
          {!formData?.password && editForm && <Alert severity="info">Enter Password to submit</Alert>}
        </CardContent>
            

        {editForm && <CardActions>
          <LoadingButton
            variant="outlined"  
            disabled={!formData?.password}          
            loading={loading}      
            onClick={handleSubmit}        
            >
            Submit
          </LoadingButton>
        </CardActions>}

        </CustomTabPanel>
      )
        
      })}
    </Box>

    </MyAccordion>
  )
}