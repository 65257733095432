import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// M-UI
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

import { 
  AppBar,
  Box,
  Badge,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Toolbar,
  Typography,
  Menu,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText } from '@mui/material';

import { AuthContext } from 'context/AuthContext';
import { AdminContext } from 'context/AdminContext';
import UserAccess from 'context/permissions/UserAccess';
  
import { userMenu } from './config/userMenu';

const logo = require('../../../assets/images/logo.png')

function ResponsiveAppBar(props) {
  const { user, profile, setCurrentOrganization, currentOrg, native } = useContext(AdminContext)
  const {logout } = useContext(AuthContext);    
  const {pages, isNative} = props;
  const navigate = useNavigate()
  const { organization } = useParams();
  
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);  

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateTo = (href) => {
    navigate(href)    
  }  
  
  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{justifyContent:'space-between'}}>          
          <Box
            component="img"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, maxWidth: '50px', height: 'auto' }}
            alt="Team Manager"
            src={logo}
          />
          <Typography
            variant="h6"
            noWrap                        
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {!organization ? 'TeamManager' : `${currentOrg.shortName || 'TeamManager'}`}
          </Typography>

          {(!isNative && user) && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                mt: 1
              }}
            >
            <List component='nav'>
              {pages.map((page) => (                
                <ListItemButton 
                  key={page.text}
                  onClick={() => {handleCloseNavMenu(); navigateTo(page.href)}}
                >
                  {page.icon && 
                  <ListItemIcon>{page.icon}</ListItemIcon>}
                  <ListItemText primary={page.text} />
                </ListItemButton>                                  
              ))}     

              {!organization && 
              <UserAccess restriction={'team-assistant'}>
                <ListItemButton
                  onClick={ () => {handleCloseNavMenu(); navigateTo(`${currentOrg.slug}/admin`)} }  
                >
                  <ListItemIcon><DashboardIcon /></ListItemIcon>
                  <ListItemText primary={'Dashboard'} />
                </ListItemButton>                  
               

               
              </UserAccess>}
              </List>
            </Menu>
          </Box>}

          
          {/* <ScoreboardIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}   
          <Box
            component="img"
            sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, maxWidth: '50px', height: 'auto' }}
            alt="Team Manager"
            src={logo}
          />       
          <Typography
            variant="h5"
            noWrap            
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {!organization ? '' : `${currentOrg.shortName || ''}`}
          </Typography>
          {(!isNative && user) && <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <UserAccess restriction={page.access} key={page.text}>
                <Button                  
                  onClick={() => {handleCloseNavMenu(); navigateTo(page.href);} }
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                  {page.text}
                </Button>
              </UserAccess>
            ))}
            {!organization && 
              <UserAccess restriction={'team-assistant'}>
                <Button 
                  onClick={ () => {handleCloseNavMenu(); navigateTo(`${currentOrg.slug}/admin`)} }
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Dashboard
                </Button>            
              </UserAccess>}

          </Box>}
          {user && 
            <Box sx={{ flexGrow: 0 }}>

            <Tooltip title="Open user menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Badge                
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={<ExpandMoreIcon />}
                  
                >
                  <Avatar 
                    alt={`${user.firstName} ${user.lastName}`}                   
                    src={`/${user.avatar}`} 
                    />
                </Badge>
              </IconButton>
            </Tooltip>            

            <Menu
              sx={{ mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <List component='nav'>
            
                {userMenu.map((setting) => {                         
                  return (
                    <ListItemButton
                      key={setting.id} 
                      onClick={() => {handleCloseUserMenu(); navigateTo(setting.href)}}
                    >
                      <ListItemIcon>{setting.icon}</ListItemIcon>
                      <ListItemText primary={setting.text} />
                    </ListItemButton>              
                  )                                
                })}

                {!organization && profile?.length > 1 && profile.map((field) => {                  
                  return (                    
                    <MenuItem key={field.organization.id} 
                      onClick={ () => {setCurrentOrganization(field)} }>
                      <Typography textAlign="center">
                        {field.organization.name}
                      </Typography>
                    </MenuItem>            
                  )                  
                })}     


                <ListItemButton key={'Logout'} onClick={logout}>
                  <ListItemIcon><LogoutIcon /></ListItemIcon>
                  <ListItemText primary={'Logout'} />
                </ListItemButton> 

              </List>
            </Menu>

          </Box>}

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;