import React, { useState, useEffect } from "react";

import { IconButton, CardHeader, Alert } from "@mui/material";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import DevicesIcon from '@mui/icons-material/Devices';

import DownloadApi from "api/DownloadApi";
import MyAccordion from "components/ui/accordion/MyAccordion";
import useIsAndroid from "hooks/useIsAndroid";
import useIsIOS from "hooks/useIsIOS";
import useIsPWAInstalled from "hooks/useIsPWAInstalled";
import usePWAInstallPrompt from "hooks/usePWAInstallPrompt";

const downloadApp = async (app) => {
  try {
    const res =  await DownloadApi.downloadApp(app)
    console.log(res)
    if (res.status === 200) {
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'TeamManager.apk';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
    
  } catch (err) {
    console.log(err)
  } finally {
    console.log('done')
  }
}

export function DownloadApps() {
  const isAndroid = useIsAndroid()
  const isIOS = useIsIOS()
  const isInstalled = useIsPWAInstalled()
  const { isInstallable, promptInstall } = usePWAInstallPrompt();  

  return (
    <React.Fragment>
    {(!isInstalled && isInstallable || !isInstalled && isIOS) &&
      <MyAccordion title={'Download Apps'}>

      {isAndroid && 
        <CardHeader
          subheader={'Download Android'} 
          action={
            <IconButton onClick={() => downloadApp('android')}>
              <AndroidIcon sx={{color: '#a4c639'}} />
            </IconButton>
          }       
      />}

      {isIOS && 
        <React.Fragment>
          <CardHeader
            subheader={'Download IOS'} 
            action={
              <IconButton>
                <AppleIcon  />
              </IconButton>
            }       
            />
          <Alert severity="info">
            To install this app, tap "Share" and then "Add to Home Screen".
          </Alert>
        </React.Fragment>
      }

      {isInstallable && 
        <CardHeader 
          subheader={'Download PWA'} 
          action={
            <IconButton onClick={promptInstall}>
              <DevicesIcon  />
            </IconButton>
          }
      />}

    </MyAccordion>}
    </React.Fragment>
  )
}