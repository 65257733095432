import React, { createContext, useState, useEffect } from "react";

import VenueApi from "api/VenueApi";

export const VenueContext = createContext(false)

export const VenueProvider = ({children}) => {
  const [venues, setVenues] = useState([])
  const [loading, setLoading] = useState(false)

  const getAllVenues = () => {
    setLoading(true)
    VenueApi.get().then((res) => {
      if (res.status === 200) {
        setVenues(res.data.doc)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const deleteVenue = (id) => {
    setLoading(true)
    VenueApi.delete(id).then((res) => {
      getAllVenues()
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getAllVenues()
  }, [])



  const value = {venues, setVenues, loading, deleteVenue, getAllVenues}
  return (
    <VenueContext.Provider value={value}>
      {children}
    </VenueContext.Provider>
  )
}

