import React, { useContext, useEffect, useState } from "react";
import RegApi from "api/RegApi";
import { AppLoadContext } from "context/AppLoadContext";
import { RegistrationContext } from "context/RegistrationContext";

import MyCard from "components/ui/card/MyCard";
import MyModal from "components/ui/modal/MyModal";

// M-UI
import { CardHeader, ButtonGroup, CardContent, CardActions, TextField, Alert, Link, Typography} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { userConfig, userPassword } from "./RegisterConfig";

import { useParams } from 'react-router-dom';


const NewUserRegistrationPage = (props) => {  
  const {nextStep, regUser} = props
  const [personalInformation, setPersonalInformation] = useState();  
  const { appLoading } = useContext(AppLoadContext)  
  const { invitation, confirmToken } = useContext(RegistrationContext)
  const { token } = useParams();  
  const [awaiting, setAwaiting] = useState(false);

  const [error, setError] = useState(false)
  const [verified, setVerified] = useState(false);   
  const [errorMsg, setErrorMsg] = useState(false);

  const handlePersonalInformation = (e) => {
    setPersonalInformation({
      ...personalInformation,      
      [e.target.id]: e.target.value
    })
    e.target.validity.valid ? 
    setError({...error, [e.target.id] : false}) :
    setError({...error, [e.target.id] : true})    
  }

  const registerUser = async () => {    
    const validate = verified ? '' : true;

    setAwaiting(true)
      const user = {
        firstName: personalInformation.firstName,
        lastName: personalInformation.lastName,
        password: personalInformation.password,
        passwordConfirm: personalInformation.passwordConfirm
      }
      const contact = {
        tel: { value: personalInformation.tel },
        email: { value: invitation.email }
      }
      const body = {user, contact}
      await RegApi.registerNewUser(token, body, validate).then((res) => {  
        if (res?.status === 200 && !verified) {
          setVerified(true);           

        }
        if (res?.status === 200 && verified) {          
          regUser(res.data.profile)
          //navigate(`../${organization}/registerplayer/${token}`) 
          nextStep()
        }        
        if (res?.status === 401) {
          setErrorMsg(res.data.msg)
        }
      }).catch((err) => {        
        console.log(err)
      }).finally(() => {
        setAwaiting(false)
      })    
  }
  
  const disableVerify = personalInformation && personalInformation.firstName && personalInformation.lastName && personalInformation.tel ? false : true;
  const passwordLength = personalInformation && personalInformation.password && personalInformation.password.length < 8 ? true :  false;
  const passwordMatch = personalInformation && personalInformation.password && personalInformation.passwordConfirm && (personalInformation.password === personalInformation.passwordConfirm);
  const passwordError = personalInformation && (personalInformation.password !== personalInformation.passwordConfirm);  
  const disabledCreateAccount = passwordMatch && !passwordError && !passwordLength ? false : true;  

  useEffect(() => {
    confirmToken(token)
  }, [token])  
  
  return (
    <>
      {invitation &&  

        <MyCard>
          <CardHeader 
            sx={{textAlign: 'center'}}
            title={`You have received an invitation to join ${invitation.organization.name}.`} 
          />                      
          <CardContent> 
          <Typography variant="body">
          {invitation.invitedBy.firstName} {invitation.invitedBy.lastName} has extended an invitation to join {invitation.organization.name}.<br/>
          {invitation?.team?.name && `Team: ${invitation.team.division} ${invitation.team.name}`}<br/>
          By registering you are accepting this invitation.
        </Typography>     
            {userConfig.map((field) => {                            
                return (
                  <TextField                
                    sx={{m:2, ml:0, width: '100%' }}   
                    key={`${field.id}_key`}         
                    id={field.id}                      
                    type={field.type}
                    label={field.field}                  
                    defaultValue={invitation[field.id] || ''}  
                    required           
                    disabled={invitation.accepted}           
                    InputProps={{
                      readOnly: field.readOnly || verified
                    }}
                    onChange={(e) => { handlePersonalInformation(e) }}
                  > 
                  </TextField>                             
                )
            })}  
            {verified && userPassword.map((field) => {
              return (
                <TextField                
                sx={{m:2, ml:0, minWidth: '50ch' }}   
                key={`${field.id}_key`}         
                id={field.id}                      
                type={field.type}
                label={field.field}                  
                defaultValue={invitation[field.id] || ''}  
                onChange={(e) => { handlePersonalInformation(e) }}                
                error={passwordError || passwordLength}
                helperText={(passwordError && 'Password does not match') || (passwordLength && 'Password min 8 chars')}
                required  
              > 
              </TextField>              
              )
              })}
          </CardContent>   
          {errorMsg && 
            <Alert 
              severity="error"
              onClose={() => setErrorMsg(false)}>
               {errorMsg}
            </Alert>}              
          <CardActions>        
            <ButtonGroup>
              <LoadingButton 
                loading={awaiting || false}
                variant="outlined"                           
                disabled={verified ? disabledCreateAccount : disableVerify}                
                onClick={registerUser}>
                  {verified ? 'Create Account' : 'Verify'}
              </LoadingButton>              
            </ButtonGroup>    
          </CardActions>
              {invitation.accepted && <>
                <Alert sx={{my: 2}} severity="error">This invitation has been accepted.</Alert> 
                <Link href="/" underline="none" sx={{m: 2}}>Go home</Link></>}
        </MyCard>        
      } 

      {!invitation && !appLoading &&
        <MyModal 
          title={'Your invitation has expired!'}
          body={'Please contact your administration.'}
        />
      }           
    </>   
  )

}

export default NewUserRegistrationPage