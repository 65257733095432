import React, { useState, useEffect, useContext } from "react";
import { ListItem } from "@mui/material";
import MyAccordion from "components/ui/accordion/MyAccordion";
import { UserContext } from "context/UserContext";

const InvitationResponses = (props) => {  
  const { event } = props
  const [invited, setInvited] = useState([])
  const [attendees, setAttendees] = useState([])
  const [notAttending, setNotAttending] = useState([])

  const sortAttendees = () => {
    let inv = []
    event.invited.forEach((person) => {      
      inv.push(person)      
    })
    setInvited(inv)
    let den=[]
    event.notAttending.forEach((person) => {
      den.push(person)      
    })
    setNotAttending(den)
    let att=[]
    event.attendees.forEach((person) => {
      att.push(person)      
    })
    setAttendees(att)
  }

  useEffect(() => {    
    sortAttendees()
  }, [event])

  

  return (<>

    {attendees.length > 0 && 
      <MyAccordion title={`Attending (${attendees.length})`}>
        {attendees.map((person, index) => {      
          return (  
            <ListItem key={index}>{person.fullName}</ListItem>
          )
      })}
    </MyAccordion>}

    {invited.length > 0 && 
      <MyAccordion title={`Invited (${invited.length})`}>
        {invited.map((person, index) => {      
          return (  
            <ListItem key={index}>{person.fullName}</ListItem>
          )
      })}
    </MyAccordion>}

    {notAttending.length > 0 && 
      <MyAccordion title={`Unavailable (${notAttending.length})`}>
      {notAttending.map((person, index) => {      
        return (  
          <ListItem key={index}>{person.fullName}</ListItem>
        )
      })}
    </MyAccordion>}

  </>)
}


export default InvitationResponses;


