import api from "./_api";

class RegApi {    
  verifyInviteToken(token) {
    return api.post('registration/verifyToken', token)
  }

  registerNewUser(token, data, verify) {    
    return api.post(`registration/newuser/${token}/${verify}`, data)
  }

  registerExistingUser(data, token) {
    return api.post(`registration/existinguser/${token}`, data)
  }

  registerPlayer(data, token) {
    return api.post(`registration/newplayer/${token}`, data)
  }
}

export default new RegApi()