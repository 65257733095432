import React, { useState, useContext } from "react";

import InvitationTable from "./InvitationTable";
import NewInvitationForm from "./NewInvitationForm";

const InvitationMain = () => {
  const [openForm, setOpenForm] = useState(false)  
  
  const toolbarAction = () => {
    setOpenForm(!openForm)
  } 
    return (
      <>      
        {!openForm && 
          <InvitationTable toolbarAction={toolbarAction} />}          
        {openForm && <NewInvitationForm handler={toolbarAction} />}
      </>      
    )
}

export default InvitationMain