import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

//import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

const ThemeProviderContext = React.createContext({ ThemeSwitch: () => {} });

const ThemeSwitch = ({ children }) => {
  const [mode, setMode] = useState(localStorage.getItem('theme') || 'dark');
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const colorMode = React.useMemo(
    () => ({
      ThemeSwitch: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(() =>
      createTheme({
        palette: {          
          mode,          
        },        
        components: {
          MuiCssBaseline : {
            styleOverrides: {              
              body: {
                //background: mode === 'dark' ? "#141414" : '#efefef'
                backgroundImage: mode === 'dark' ? 
                "linear-gradient(rgba(0,0,0) 60%,rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(./images/bg-image-one.png)" :
                "linear-gradient(rgba(235, 242 ,250) 50%, rgba(235, 242 ,250,0.8)), url(./images/bg-image-one.png)",
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                backgroundAttachment: "fixed",
                height: '100vh',
                '&.login': {                  
                  backgroundImage: mode === 'dark' ? 
                  "linear-gradient(rgba(0,0,0) 60%,rgba(0,0,0,0.8), rgba(0,0,0,0.9)), url(./images/bg-image-two.png)" :
                  "linear-gradient(rgba(235, 242 ,250) 50%, rgba(235, 242 ,250,0.9)), url(./images/bg-image-two.png)",
                  backgroundPosition:'center'
                },
                '&.registration': {
                  backgroundImage: mode === 'dark' ? 
                  "linear-gradient(rgba(0,0,0) 60%,rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(/../images/bg-image-two.png)" :
                  "linear-gradient(rgba(235, 242 ,250) 50%, rgba(235, 242 ,250,0.8)), url(/../images/bg-image-two.png)",
                  backgroundPosition: 'center'
                }
              }
            }
          },
          MuiPageTitle: {},
          MuiDataGrid: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'dark' ? 'rgba(0,0,0,0.5)' : '#efefef',
                boxShadow: '0px 0px 2px'
              },
              cell: {
                backgroundColor: mode === 'dark' ? 'rgba(0,0,0.5)' : '#efefef'
              },
              columnHeader: {
                backgroundColor: mode === 'dark' ? 'rgba(0,0,0.5)' : '#efefef'
              }
            }
          },
          MuiCard: {
            styleOverrides: {
              root: {
                background: mode === 'dark' ? 'rgba(0,0,0,0.3)' : '#efefef'
              }
            }
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                background: mode === 'dark' ? 'rgba(0,0,0,0.3)' : '#efefef',
                '&.Mui-expanded': {
                  margin: '0px',                  
                }
              },
            }
          },                   
          MuiAppBar: {
            styleOverrides: {
              root: {
                background: mode === 'dark' ? '#161314' : '#1D4CD7',
                color: mode === 'dark' ? '#fff' : '#fff'
              }
            }
          }
        }        
      }),
    [mode],
  );  

  useEffect(() => {
    localStorage.setItem('theme', mode)
  }, [mode])

  return (
    <ThemeProviderContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </ThemeProviderContext.Provider>
  );
}

export const useGlobalContext = () => {
  return useContext(ThemeSwitch)
}

export {ThemeSwitch, ThemeProviderContext}

