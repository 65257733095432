import React, { useContext, useEffect, useState } from "react";

import {
  GridRowModes,
  DataGrid,  
  GridToolbarContainer,
  GridActionsCellItem  
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

import { OrganizationContext } from "context/OrganizationContext";
import { AdminContext } from "context/AdminContext";

import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';


import NewOrganizationForm from "./NewOrganizationForm";

import PageTitle from "components/ui/typography/PageTitle";
import { organizationColumns } from "./OrganizationConfig";



const OrganizationMain = () => {    
  const { organization, setOrganization, loading } = useContext(OrganizationContext)  
  const { userRole } = useContext(AdminContext)
  const [openNewOrgForm, setOpenNewOrgForm] = useState(false)
  const [ selected, setSelected ]  = useState(false)

  // Table
  const [rowModesModel, setRowModesModel] = useState({});  
  const [columns, setColumns] = useState([])  

  const isDev = userRole.includes('dev')
  
  //Table  
  function EditToolbar(props) {
    const { handleOpenNewForm} = props;
  
    const handleClick = () => {
      handleOpenNewForm()
    };
  
    return (
      <GridToolbarContainer>        
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            New Organization
          </Button>        
      </GridToolbarContainer>
    );
  }

  const handleEditClick = (id) => () => {      
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {    
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = organization.find((row) => organization.id === id);
    if (editedRow?.isNew) {      
    }
  };

  const handleDeleteClick = (id) => async () => {    
    // not yet handled in server    
    // return setOrganization(organization.filter((row) => row.id !== id));        
  };

  const onRowsSelectionHandler = (ids) => {    
    const selectedRowsData = ids.map((id) => organization.find((row) => row._id === id));            
    handleSelection(selectedRowsData)        
  };
  //End Table

  const handleOpenNewForm = () => {
    setOpenNewOrgForm(!openNewOrgForm)    
  } 

  const handleSelection = (org) => {    
    if (userRole.includes('dev') || 
    userRole.includes('director') || 
    userRole.includes('staff') ) {
          setSelected(org)
    }
  }
  
  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;        

        if (isInEditMode) {
          return [            
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              title='Delete'              
              disabled={true}
              onClick={handleDeleteClick(id)}
              sx={{
                color: 'primary.main',                
              }}              
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="inherit"
            onClick={handleEditClick(id)}            
          />
        ];
      },
    },

  ]
  
  useEffect(() => {
    if (isDev) {setColumns(actions.concat(organizationColumns))}
    else {setColumns(organizationColumns)}
  }, [])
  
  useEffect(() => {
    if (isDev) {setColumns(actions.concat(organizationColumns))}
    else {setColumns(organizationColumns)}
  }, [rowModesModel])


  
  return (<>
      <PageTitle title={'Manage Organization'} />
      {!openNewOrgForm && !selected &&          
       <DataGrid
       autoHeight
       rows={organization}
       columns={columns}
       getRowId={(row) => row._id || row.id}          
       loading={loading}       
       onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)} 
       slots={{
        loadingOverlay: LinearProgress,
         toolbar: isDev && EditToolbar
        }}
        slotProps={{
          toolbar: {handleOpenNewForm}        
        }}            
        />}      
      {openNewOrgForm && <NewOrganizationForm handler={handleOpenNewForm} />}
      {!openNewOrgForm && selected && 
        <NewOrganizationForm 
          handler={handleSelection} 
          data={selected}
        />}
  </>)  
}

export default OrganizationMain