import React, {useContext, useState, useEffect} from "react";

import { InvitationContext } from "context/InvitationContext";
import { OrganizationContext } from "context/OrganizationContext";
import { TeamContext } from "context/TeamContext";

import {Card, CardContent, TextField, Stack, CardActions, Box, ButtonGroup } from "@mui/material";
import { CardHeader, Button, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { newInviteFields } from "./InvitationTableConfig";
import { AdminContext } from "context/AdminContext";

const NewInvitationForm = (props) => {
  const { handler } = props;
  const { newInvitation, loading } = useContext(InvitationContext);
  const { organization } = useContext(OrganizationContext);
  const { team } = useContext(TeamContext)
  const { userRole } = useContext(AdminContext)
  const [formData, setFormData] = useState([])
  const [error, setError] = useState(false)    

  const handleForm = (e) => {
    let target = e.target.id || e.target.name      
    
    setFormData({
      ...formData,
      [target] : e.target.value
    })
    e.target.validity && e.target.validity.valid ? 
    setError({...error, [target] : false}) :
    setError({...error, [target] : true});               
  }

  const submit = () => {    
    newInvitation(formData)
    handler()
  }

  useEffect(() => {
    if (organization && organization.length === 1)
      setFormData({
        ...formData,
        organization: organization[0]._id
    })
  }, [organization])

  const disableButton = formData.email && formData.role && formData.organization && !error.email;



  return (
    <Card>
      <CardActions>
        <Button variant="outlined" onClick={handler}>Back</Button>
      </CardActions>
      <CardHeader title={'Send a new invitation.'} />        

    <CardContent>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}      
    >
      {newInviteFields && newInviteFields.map((field) => {
        if (!field.options) {
          return (            
            <TextField          
              key={field.id}                       
              id={field.id}       
              name={field.id}               
              type={field.type}
              label={field.label}                 
              onChange={(e) => handleForm(e)}    
              autoComplete="true"            
              InputProps={{
                readOnly: field.readOnly ? 'true' : false
              }}              
              require='true'
              error={error[field.id]}
              required      
            >           
            </TextField>                             
          )
        } else {
          return (
            <>          
              <TextField
                key={field.id}                
                select
                id={field.id}                
                label={field.default}
                type="select"       
                name={field.id}                                      
                onChange={(e) => handleForm(e)}                   
                defaultValue = ""                
                required
               >
              {field.options.map((opt) => {
                const access = userRole.toString()
                return (
                  <MenuItem key={`${opt.value}_org`} value={opt.value} defaultValue={field.default} disabled={!opt.access.includes(access)}>
                        {opt.label}
                  </MenuItem>
                )
              })}
              </TextField>
            </>            
          )
        }
      })} 

      <TextField
          select
          id='organization'
          label="Select Organization"
          name='organization'    
          defaultValue = ""   
          value={formData.organization || ''}                 
          onChange={(e) => handleForm(e)}          
          required
        >
          {organization.map((org, index) => {
            return (
              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
            )
          })
          }
        </TextField> 


        <TextField
          select         
          id={'team'}
          label={"Select Team"}
          name={'team'}
          defaultValue={""}
          value={formData?.team || ''}                    
          onChange={(e) => handleForm(e)}                   
        >
          {team.map((team) => {
            return (
              <MenuItem key={`${team._id}_org`} value={team._id} defaultValue={team.name}>{team.division} : {team.name}</MenuItem>
            )
          })
          }
        </TextField> 

      </Box>
    </CardContent>
    <CardActions>
      <Stack spacing={2} direction="row"> 
      <ButtonGroup>
        <LoadingButton 
          variant="outlined"
          loading={loading}
          disabled={!disableButton}
          onClick={submit}
        >
          Submit
        </LoadingButton>        
      </ButtonGroup>         
        </Stack>
    </CardActions>
  </Card>    

  )
}

export default NewInvitationForm
