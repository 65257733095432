import React, {useState, createContext, useEffect} from 'react';

import io from 'socket.io-client';
import Cookies from 'js-cookie';
import notificationSound from '../assets/audio/notification.mp3'
export const ChatContext = createContext(false)

export const ChatProvider = ({children}) => {
  const [socket, setSocket] = useState();
  const audio = new Audio(notificationSound)  

  const cookie = Cookies.get('jwt')
  const chatSocket = io({
    autoConnect: false,
    auth: {
      token: cookie
    }
  });
  
  const connectSocket = () => {
    chatSocket.connect();
  }

  useEffect(() => {
    connectSocket();
    setSocket(chatSocket)   
    chatSocket.on('message', (message) => {               
    });

  },[])  
    
    

  const value={ socket }

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  )  
}


