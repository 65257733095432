import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
  const { field, data, handleForm, multiple, state, readOnly} = props;
  const [personName, setPersonName] = React.useState(state);  
  
  const theme = useTheme();

  const handleChange = (event) => {
    handleForm(event)
    const {target: { value }, } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  React.useEffect(() => {    
    if (data && data.length) {
      setPersonName(data)
    }
  }, [data])
  
  return (

      <FormControl sx={{ m: 1, maxWidth: 500, width: '100%' }}>
        <InputLabel id={field._id}>{field.label}</InputLabel>
        <Select
          labelId={field._id}
          id={field._id}          
          name={field._id}          
          multiple={multiple}
          value={personName}
          onChange={(e) => handleChange(e)}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}          
          disabled={readOnly}
        >          
        <MenuItem value={[]}> - Clear - </MenuItem>
          {field.options.map((name) => (
            <MenuItem
              key={name._id}
              value={name?.user?._id || name._id}
              style={getStyles(name, personName, theme)}
            >
              {name?.firstName && 
                `${name.firstName} ${name.lastName} ${name.division ? ` | Age: ${name.division}` : ''}`}

              {name?.user?.firstName && 
                `${name.user.firstName} ${name.user.lastName}`}
                
              {name?.label &&
                name.label}
              
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
