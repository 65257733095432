import React, { useEffect, useState } from "react";

import { CardHeader, Alert, CardContent } from "@mui/material";

import MyAccordion from "components/ui/accordion/MyAccordion";


export function NotificationsSetting() {
  const [permission, setPermission] = useState(false)
  
  useEffect(() => {
    const storage = localStorage.getItem('pn')
    setPermission( storage === 'true' ? true : false)
  }, [])

  return (
    <MyAccordion title={'Permissions'}>

      <CardHeader subheader={'Push Notifications'} />
      <CardContent>
        <Alert severity="info">Notification permission was {permission ? 'granted' : 'denied'}. Visit device settings to change this setting.</Alert>              
      </CardContent>
      
      

    </MyAccordion>
  ) 
}