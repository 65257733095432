import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';

import { InvitationProvider } from 'context/InvitationContext';
import { MemberProvider } from "context/MemberContext";
import { OrganizationProvider } from 'context/OrganizationContext';
import { TeamProvider } from "context/TeamContext";
import { PlayerProvider } from "context/PlayerContext";
import { VenueProvider } from "context/VenueContext";
import { EventProvider } from "context/EventContext";

import UserAccess from "context/permissions/UserAccess";

// Material UI
import ResponsiveAppBar from 'components/ui/navigation/ResponsiveAppBar';
import Container from '@mui/material/Container';

// Admin Pages
import adminNav from "components/ui/navigation/config/adminNav";
import OrganizationMain from "./organization/OrganizationMain";

import MembersMain from "./members/MembersMain";
import InvitationMain from "./members/invitation/InvitationMain";
import UserMain from "./members/users/UsersMain";

import TeamMain from "./teams/TeamsMain";
import TeamTable from "./teams/team/TeamTable";
import PlayerTable from "./teams/players/PlayerTable";

import ScheduleMain from "./schedule/ScheduleMain";
import ScheduleTable from "./schedule/events/ScheduleTable";
import VenueTable from "./schedule/venues/VenueTable";

// End Admin Pages



const AdministrationIndex = () => {
  
  return (
    <UserAccess restriction={'team-assistant'}>
      <OrganizationProvider>
        <InvitationProvider>
          <MemberProvider>
            <TeamProvider>
              <PlayerProvider>
                <VenueProvider>
                  <EventProvider>

                    <ResponsiveAppBar pages={adminNav}/>
                    <Container sx={{mt: 2, mb: 5, maxWidth:"xl" }}>
                      
                      <UserAccess restriction={'staff'}>
                        <Routes >
                          <Route path="/organization" element={<OrganizationMain />} />
                        </Routes>
                      </UserAccess>              

                      <UserAccess restriction={'team-manager'} >
                        <Routes>
                          <Route path="members" element={<MembersMain />} >
                            <Route index element={<UserMain />} />
                            <Route path="invitations" element={<InvitationMain /> }/>
                          </Route>                       
                        </Routes>
                      </UserAccess>

                      <UserAccess restriction={'team-assistant'} >
                        <Routes>
                          <Route path="/" element={<OrganizationMain />} />          
                          <Route path="/teams" element={<TeamMain />} >
                            <Route index element={<TeamTable />} />
                            <Route path='players' element={<PlayerTable />} />
                            {/* <Route path='members' element={<UserMain />} />
                            <Route path="invitations" element={<InvitationMain /> }/> */}
                          </Route>
                          <Route path="/schedules" element={<ScheduleMain />}> 
                            <Route index element={<ScheduleTable />} />
                            <Route path='venues' element={<VenueTable />} />
                          
                          </Route>                  
                        </Routes>
                      </UserAccess>              

                    </Container> 
                    
                    </EventProvider>
                </VenueProvider>
              </PlayerProvider>  
            </TeamProvider>   
          </MemberProvider>
        </InvitationProvider>
      </OrganizationProvider>
    </UserAccess>
  )
}

export default AdministrationIndex