import React from "react";
import {  Card, CardHeader } from '@mui/material'

import { NotificationsSetting } from "./NotificationsSetting";
import { DownloadApps } from "./DownloadApps";
import { ThemeSetting } from "./ThemeSetting";


export function Settings() {
  return (
    <Card>
      <CardHeader
        title={'Settings'} 
      />

      <NotificationsSetting />
      {/* <ThemeSetting />       */}
      <DownloadApps />

    </Card>
  )
}