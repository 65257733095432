import React, {useState, createContext, useEffect} from "react";

export const AppLoadContext = createContext(false)

export const AppLoadProvider = ({ children }) => {
  const [appLoading, setAppLoading] = useState(false)  

  return <AppLoadContext.Provider value={{setAppLoading, appLoading}}>
    {children}
  </AppLoadContext.Provider>
}