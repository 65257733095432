import React, { useState, useEffect, useContext } from "react";
import validator from "validator";

import { Card, IconButton, CardContent, Divider, FormControl, InputAdornment, InputLabel, LinearProgress } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

import OutlinedInput from '@mui/material/OutlinedInput';

import { AdminContext } from "context/AdminContext";
import { ChatContext } from "context/ChatContext";
import dayjs from "dayjs";

import { ChatDate } from "./components/ChatDate";
import { ChatMessage } from "./components/ChatMessage";

import notificationSound from 'assets/audio/notification.mp3'
import { ChatAvatarGroup } from "./components/ChatAvatarGroup";
import { ChatHeader } from "./components/ChatHeader";

export function ChatList(props) {
  const { user } = useContext(AdminContext)  
  const { socket } = useContext(ChatContext)
  const [ loading, setLoading ] = useState(false)
  const { room, handleSelectedTeam } = props;
  const [ roomUsers, setRoomUsers] = useState([])
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [height, setHeight] = useState()
  const last = document.getElementById('lastElement')
  let prevDate = null;

  const handleResize = () => {
    setHeight(window.innerHeight)
  }

  const sendMessage = () => {
    setLoading(true)
    const newMessage = { room: room._id, user, message };
    socket.emit('send-message', newMessage);
    setMessage('');
  };

  const handleLeaveRoom = () => {
    socket.emit('leave-room', {room: room._id, user})
    socket.off()
    window.removeEventListener("resize", handleResize); 
  }  

  useEffect(() => {
    last?.scrollIntoView()
  }, [messages])

  useEffect(() => {  
    setLoading(true)    
    handleResize()
    window.addEventListener("resize", handleResize, false); 
    const audio = new Audio(notificationSound)         

    socket.emit('join-room', {room: room._id, user}, (messages) => {
      setLoading(true)
      setMessages(messages);        
    })
      
    socket.emit('get', {room: room._id}, (messages) => {
      setLoading(true)
      setMessages(messages);            
    });    
    
    socket.on('output-messages', (messages) => {
      setMessages(messages);
      setLoading(false)
    });
    
    socket.on('message', (message) => {
      setMessages(prevMessages => [...prevMessages, message]);                    
      setLoading(false)      
      audio.play()    
    })

    socket.on('room-users', (data) => {
      setRoomUsers(data)
    })    
    
    return () => {
      handleLeaveRoom()
    };        
  }, []);   


    return (
      <Card sx={{position: 'relative', mb:5}}>
        <ChatHeader room={room} action={handleSelectedTeam}/>
        
        <Divider />

        <CardContent 
          sx={{
            //height: {xs:'60vh', md:'100%'},      
            height: height - 275,
            overflowY:'auto',
            p:0,
            px: 1,
            position:'relative'            
          }}
          >
          <ChatAvatarGroup roomUsers={roomUsers} />
          {messages.map((msg, index) =>{              
            const date = dayjs(msg.timestamp).format("ddd, MMM DD, YYYY");
            const isNew = date !== prevDate;
            prevDate = date;
            return (            
              <React.Fragment key={index}>
                {isNew && <ChatDate date={date} />}                
                <ChatMessage msg={msg} />        
              </React.Fragment>
            )}
          )}           

          <div id="lastElement"></div>

          </CardContent>

          {loading && <LinearProgress />}


          <FormControl sx={{ m: '0 auto', width: '100%' }} variant="outlined">
            <InputLabel htmlFor="message_input"></InputLabel>
            <OutlinedInput
              id="message_input"
              type={'text'}
              value={message}              
              onChange={(e) => setMessage(e.target.value)}  
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={validator.isEmpty(message, { ignore_whitespace: true })}
                    aria-label="send message"                  
                    edge="end"
                    onClick={sendMessage}
                    >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }              
              />
          </FormControl>                

      </Card>
    );
}