import React, { createContext, useState, useContext, useEffect } from "react";
import { AdminContext } from "./AdminContext";

import TeamApi from "api/TeamApi";
import TeamPlayerApi from "api/TeamPlayerApi";


export const TeamContext = createContext(false)

export const TeamProvider = ({children}) => {
  const { currentOrg } = useContext(AdminContext)    
  const [loading, setLoading] = useState(false)
  const [team, setTeam] = useState([])
  const [asstMan, setAsstMan] = useState([])
  const [teamMan, setTeamMan] = useState([])
  const [teamPlayers, setTeamPlayers] = useState([])
  
  const getTeams = async () => {
    setLoading(true)
    await TeamApi.get().then((res) => {
      if (res.status === 200) {
        setTeam(res.data.doc)
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateTeam = async (id, data) => {
    await TeamApi.update(id, data).then((res) => {       
      if (res.status == 201) {
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      getTeams()
    })
    return true
  }

  const getTeamStaff = async () => {
    setLoading(true)
    await TeamApi.getManagers(currentOrg.id)
    .then((res) =>{
      if( res.status === 200) {        
        setAsstMan(res.data.assistantManagers)
        setTeamMan(res.data.teamManagers)
      }
    }).finally(() => {
      setLoading(false)
    })
    
  }

  const getTeamPlayers = async (teamId) => {
    setLoading(true)
    await TeamApi.getPlayersFromTeam(teamId)
    .then((res) => {      
      setTeamPlayers(res.data.doc.roster)
    }).finally(() => {
      setLoading(false)      
    })

  }

  const editRosteredPlayer = async (player) => {
    setLoading(true)    
    const id = player._id
    await TeamPlayerApi.update(id, player)
    .then((res) => {
      //res.data.player      
    }).finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    getTeams()
    getTeamStaff()        
  },[])


  const value = {loading, setLoading, team, getTeams, setTeam,
     asstMan, teamMan, updateTeam,
     getTeamPlayers, setTeamPlayers, teamPlayers,
     editRosteredPlayer
    }

  return (
    <TeamContext.Provider value={value}>
      {children}
    </TeamContext.Provider>
  )
}
