import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserContext";

import { Avatar, Stack, Card, CardHeader, IconButton } from "@mui/material";

import ChatIcon from '@mui/icons-material/Chat';

import { ChatList } from "./Chat";
import UserAccess from "context/permissions/UserAccess";

export function RoomsList (props) {
  const { team } = props
  const { chatRooms } = useContext(UserContext)   
  const [rooms, setRooms] = useState(false)
  const [room, setRoom] = useState(false)  

  const handleSelectedTeam = (room) => {
    setRoom(room)    
  }
  useEffect(() => {
    if (team) { setRooms(team.rooms) } 
    else { setRooms(chatRooms) }
  }, [])

  
  return (
  <React.Fragment>      
    {!room && <Stack direction={'column'}>
      {rooms && rooms.map((room, index) => {        
        return (
          <React.Fragment key={room._id}>
          <UserAccess restriction={room.access} >
          <Card            
            sx={{mb: 2, cursor: 'pointer'}}  
            onClick={() => handleSelectedTeam(room)}           
          >           
            <CardHeader 
              action={
                <IconButton onClick={() => handleSelectedTeam(room)}>
                  <ChatIcon/>
                </IconButton>
              }   
              avatar={<Avatar src={`/images/${room.team.logo}`}/>}
              titleTypographyProps={{variant:'h6' }}
              title={room.access !== 'user' ? 'Admin ' + room.team.fullName : room.team.fullName}
              subheader={room.team.sport} 
              sx={{width: '100%'}} 
            />              
            
          </Card>
          </UserAccess>
          </React.Fragment>
        )
      })}
    </Stack>}
    {room && <ChatList room={room} handleSelectedTeam={handleSelectedTeam}/>}    
  </React.Fragment>
  )
}