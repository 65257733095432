import React, { useState, useContext } from "react";
import {  CardActions, Alert } from '@mui/material'
import { LoadingButton } from "@mui/lab";
import MyAccordion from "components/ui/accordion/MyAccordion";

import { AdminContext } from "context/AdminContext";
import AuthApi from "api/AuthApi";


export function ResetPassword () {
  const { user } = useContext(AdminContext)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState(false)

  const sendLink = async () => {
    setLoading(true)
    const email = user.contact.email.value
    await AuthApi.forgotPassword({email})
    .then(res => {
      if (res.status === 200) {
        setMsg({severity: 'success', msg: 'Email sent.'})
      }
    })
    .catch(err => {
      console.log(err)
      setMsg({severity: 'error', msg: 'Something went wrong.'})
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <MyAccordion title={'Reset Password'}>
      <CardActions>
        <LoadingButton
         variant="outlined"
         loading={loading}
         onClick={sendLink}
        >Reset password</LoadingButton>
      </CardActions>
        <Alert 
          severity={msg.severity || 'info'}
          onClose={() => setMsg(false)}
        >
          {msg.msg || "An email to reset your password will be sent."}
        </Alert>
    </MyAccordion>
  )
}