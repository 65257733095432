import React, {useState, createContext, useEffect, useContext} from "react";

import { AppLoadContext } from "context/AppLoadContext";

import RegApi from "api/RegApi";
import Toast from "components/notifications/Toast";



export const RegistrationContext = createContext(false);

export const RegistrationProvider = ({children}) => {
  const { setAppLoading , appLoading } = useContext(AppLoadContext)      
  const [invitation, setInvitation] = useState(false)
  

  const confirmToken = async (token) => {    
    setAppLoading(true)    
    await RegApi.verifyInviteToken({token}).then((res) => {      
    if (res.status === 200) {            
      setInvitation(res.data.invite)          
    }
    if (res.status === 400) {
      Toast({type:'error', msg: 'Invite already accepeted'})
    }    
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setAppLoading(false)
    })
  } 


  const value = {invitation, confirmToken}

  return <RegistrationContext.Provider value={value}>
    {children}
  </RegistrationContext.Provider>
}