import React from "react";

import { FormControl } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";



const MyDateTimePicker = (props) => {
  const { label, setter, data, readOnly } = props;

  const handleDate = (e) => {
    //'DD/MMMM/YYYY - hh:mm:a'
    const date = dayjs(e).format()
    setter(date)
  }

  return (
    <FormControl sx={{ m: 1, maxWidth: 500, width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker          
          label={label}     
          onChange={(e) => handleDate(e)}             
          value={dayjs(data || '')}
          readOnly={readOnly}
        />            
      </LocalizationProvider>
    </FormControl>
  )
}

export default MyDateTimePicker