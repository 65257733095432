import React, {useState, useContext, createContext, useEffect} from "react";

import { AppLoadContext } from "./AppLoadContext";
import { AdminContext } from "./AdminContext";

import InvtApi from "api/InvtApi"; 

export const InvitationContext = createContext(false)

export const InvitationProvider = ({ children }) => {
  const { userRole } = useContext(AdminContext);
  const  [loading, setLoading ] = useState(false)
  const [invitationMsg, setInvitationMsg] = useState(false)
  const { setAppLoading } = useContext(AppLoadContext);    
  const [ invitations, setInvitations ] = useState([]);    

  const newInvitation = async (data) => { 
    setLoading(true)
    await InvtApi.create(data).then((res) => {               
      InvtApi.get().then((invites) => {
        setInvitations(invites.data.doc)
      })
    }).catch((err) => {      
      setInvitationMsg({severity:'error', msg: 'Something went wrong.'})
    }).finally(() => {
      setLoading(false)      
    })
  }

  const deleteInvitation = async (id) => {
    setLoading(true)
    await InvtApi.delete(id)
    .finally(() => {
      setLoading(false)
    })

  }

  const handleResendClick = (id) => async () => {
    setLoading(true)
    await InvtApi.resend(id)
    .then((res) => {      
      if (res.status === 200) {
        setInvitationMsg({
          severity: res.data.status.toLowerCase(),
          msg: res.data.message
        })        
      }
    }).catch((err) => {
      console.log(err)
      setInvitationMsg({severity:'error', msg: 'Something went wrong.'})      
    }).finally(() => {
      setLoading(false)
    })
  }

  const getInvitations = async () => {    
    setAppLoading(true)
    await InvtApi.get().then((res) => {         
      if (res.status === 200) {        
        setInvitations(res.data.doc)        
      }      
    }).catch((err)=>{
      //console.log(err)
    }).finally(() => {
      setAppLoading(false)
    })
  }

  useEffect(() => {
    !userRole?.includes('user') && getInvitations()   
  }, [userRole])

  useEffect(() => {
    setTimeout(() => {
      setInvitationMsg(false)
    }, 5000)
    clearTimeout()    
  }, [invitationMsg])


  const value={
    invitations, setInvitations,
    newInvitation, loading,
    setInvitationMsg, invitationMsg,
    deleteInvitation, handleResendClick
  }

  return (
    <InvitationContext.Provider value={value}>
      {children}
    </InvitationContext.Provider>
  )
}