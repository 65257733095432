import { useEffect, useState } from 'react';

const useIsPWAInstalled = () => {
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);

  useEffect(() => {
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: fullscreen)').matches ||      
      window.navigator.standalone === true;

    setIsPWAInstalled(isStandalone);
  }, []);

  return isPWAInstalled;
};

export default useIsPWAInstalled;
