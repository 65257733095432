import React, { useContext, useEffect, useState } from "react";

import { RegistrationContext } from "context/RegistrationContext";
import { AppLoadContext } from "context/AppLoadContext";
import { AdminContext } from "context/AdminContext";

import RegApi from "api/RegApi";

import { newPlayerForm } from "../RegisterConfig";
import PlayerList from "./PlayerList";

import MyDatePicker from "components/ui/form/components/DatePicker";

import { Alert, Button, ButtonGroup, CardActions, CardContent, CardHeader, FormGroup, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import MyCard from "components/ui/card/MyCard";
import { LoadingButton } from "@mui/lab";

const PlayerRegistration = (props) => {
  const { players, setPlayers, user, nextStep } = props
  const [ playerInformation, setPlayerInformation ] = useState()
  const [ playerDuplicate, setPlayerDuplicate] = useState(false)
  const [ awaiting, setAwaiting ] = useState(false)  

  const { invitation, confirmToken } = useContext(RegistrationContext) 
  const { setAppLoading , appLoading } = useContext(AppLoadContext)       
  const [teamInvite, setTeamInvite] = useState(false)
  const [ dob, setDOB] = useState()
  const { token } = useParams()
  const [response, setResponse] = useState([])  

  const handleForm = (e) => {
    const target = e.target.id || e.target.value
    setPlayerInformation({
      ...playerInformation,
      invitation,
      user,
      'dob': dob,
      [target] : e.target.value
    })    
  }  

  const preStagePlayers = () => {    

      const exists = players.some(obj => 
        ( obj.firstName === playerInformation.firstName ) &&
        ( obj.lastName === playerInformation.lastName )
      );
      if (exists) setPlayerDuplicate(playerInformation)
      if (!exists || (!exists && teamInvite && players.length < 1)) {
        setPlayers([      
          ...players,            
          playerInformation
        ])        
        setPlayerInformation({})
      }
    
  }
  
  const registerPlayers = () => {
    setAwaiting(true)            
    let count = 0; let temp =[];
    players.forEach( async (player) => {                  
      count++ ; 
      await RegApi.registerPlayer(player, token)
      .then((res) => {
        if (res.data === 200) {player.confirmed = true}
        temp.push(player)      
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        if (count == players.length) {
          setPlayers(temp)
          setTimeout(() => {
            setAwaiting(false)
            nextStep()
          }, 1000)          
        }
      })

    })

  }

  useEffect(() => {
    setPlayerInformation({
      ...playerInformation,
      'dob': dob
    })
  }, [dob])


  useEffect(() => {
    setAppLoading(true)
    confirmToken(token)  
  }, [token])

  useEffect(() => {
    if (invitation && invitation.length) {      
      setAppLoading(false)
    }

    if (invitation && invitation?.team?.fullName) {
      setTeamInvite(invitation.team.fullName)
    }
  }, [invitation])

  return (
    <>{invitation && 
    <MyCard>      
      <CardHeader
        sx={{textAlign: 'center'}}
        title={invitation.organization.name}
        subheader={`Player Registration`} 
      />
      <CardContent>
        <FormGroup              
        validate='true'>
        {newPlayerForm.map((field) => {
          if (field.type === 'text') {
            return (
              <TextField
                sx={{my:1}}   
                key={`${field.id}_key`}         
                id={field.id}                      
                type={field.type}
                label={field.field}                                  
                value={playerInformation?.[field.id] || ''}
                onChange={(e) => handleForm(e)}                  
                required                                   
              />
            )
          }
          if (field.type === 'date') {
            return (
              <MyDatePicker 
              key={field.id} 
              field={field}    
              format={'MM/DD/YYYY'}   
              handleDate={setDOB}       
              />
            )
          }
        })}
        {teamInvite && 
          <TextField
            id={'team'}
            sx={{my:1}}   
            value={teamInvite || ''}
            disabled={true}
          />}
        </FormGroup>
        {playerDuplicate && 
          <Alert 
            severity='error'
            onClose={() => setPlayerDuplicate(false)}
          >
            {playerDuplicate.firstName} already added.</Alert>}
        <PlayerList players={players} setPlayers={setPlayers} />
      </CardContent>
      <CardActions>
        <ButtonGroup>
          <Button
          variant="outlined"
          onClick={preStagePlayers}
          disabled={!playerInformation?.firstName ||
                    !playerInformation?.lastName || 
                    !playerInformation?.dob}

          >
            add player
          </Button>
          <LoadingButton
            loading={awaiting}
            disabled={!players?.length}
            onClick={registerPlayers}
          >
            done          
          </LoadingButton>
            

          

        </ButtonGroup>
      </CardActions>


    </MyCard>
    }</>
  )
}

export default PlayerRegistration