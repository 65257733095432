import React from "react";

import { Stack, Divider, Avatar } from "@mui/material";

export function TeamMembers(props) {
  const {team} = props
  
  return (
    <React.Fragment>
      {team && team.parents.map((parent, i) => {
        return (
          <React.Fragment key={i}>
            <Stack direction='row' sx={{p:0, width: '100%', alignItems: 'center' }}>
              <Avatar sx={{m:1 }} src={parent.avatar} />
              {parent.fullName}
            </Stack>
            <Divider />

          </React.Fragment>
        )
      })}
  </React.Fragment>
  )
 
}