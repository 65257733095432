import React, { useContext, useRef, useState } from "react";

import { AdminContext } from "context/AdminContext";
import UploadApi from "api/UploadApi";


import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadIcon from '@mui/icons-material/Upload';

import {Box, Avatar, CardHeader, Stack, IconButton, Alert } from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';



export function UserProfile () {
  const { user, setUser } = useContext(AdminContext);
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState(false);
  const [preview, setPreview] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const fileInputRef = useRef(null);  

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  
  const handleFileChange = (event) => {
    setAvatar(event.target.files[0]);
    const prev = URL.createObjectURL(event.target.files[0])
    setPreview(prev)    
  };
  
  const submit = async () => {
    setLoading(true)
    const id = user.id              
    await UploadApi.avatar(id, {'avatar' : avatar} )
    .then(res => setUser(res.data.data))
    .catch(err => {
      console.log(err)
      setErr(true)
    })
    .finally(() => {
      setAvatar(false)
      setLoading(false)
    })
  }  

  return (<>
    <Stack 
      sx={{display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'start'
      }}>

      <Avatar alt={user.fullName} src={preview || `/${user.avatar}`} />
      <CardHeader title={user.fullName} />
      
      {(!loading && !avatar) && <IconButton onClick={handleButtonClick} >
        <ImageSearchIcon label='Search Image' />
      </IconButton>}

      {(!loading && avatar) && <IconButton onClick={submit} >
        <UploadIcon color={uploaded ? '' : 'success'} label='Upload Image' />
      </IconButton>}

      {(!loading && avatar) && <IconButton onClick={() => {setAvatar(false); setPreview(false)}} >
        <CancelIcon color='error' label='Upload Image' />
      </IconButton>}

      {loading &&
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={20} />
        </Box>}

        <input 
          id='file'
          type='file'
          name='avatar'
          ref={fileInputRef}
          style={{display: 'none'}}
          onChange={handleFileChange}
        />
    </Stack>    
    {err && <Alert severity="error" onClose={() => setErr(false)}>Something went wrong. Try again later.</Alert>}
    </>)
}