// const getChild = (value) => {  
//   let data = [];
//   value?.forEach((org) => {            
//    data.push(org.name)            
//   })
//   return data.toString()
// }

export const userColumns =  [  
  {field: 'user', headerName: 'Name', width: 150,
    valueGetter: (value, row) => {      
      return `${value?.firstName} ${value?.lastName}`
    }
  },  
  {field: 'contact.tel', headerName: 'Tel', width: 125,  
    valueGetter: (value, row) => {    
      return row?.contact?.tel?.value || row?.contact?.tel
      }
  },
  {field: 'role', headerName: 'Role', width: 125, editable: true, type: 'singleSelect',
    valueOptions: ['director', 'staff', 'team-manager', 'team-assistant', 'user'],  
    valueGetter: (value, row) => {               
      if (value){      
        let data = value?.toString()
        return data.charAt(0).toUpperCase() + data.slice(1);
      } 
    }
  },
  {field: 'contact.email', headerName: 'Email', width: 175,
    valueGetter: (value, row) => {    
      return row?.contact?.email?.value || row?.contact?.email
    }
  },  
  {field: 'team', headerName: 'Teams', width: 200, 
    valueGetter: (value, row) => {
      let data= []      
      if (value?.length === 0) return 'Team not assigned'
      value?.forEach((team) => {
        let temp = `${team.division} ${team.name}\n `
        data.push(temp)
      })
      return data
    }
  },
  {field: 'player.fullName', headerName: 'Players', width: 270, 
    valueGetter: (value, row) => {      
      let data = []
      row?.player?.forEach((player) => {
        data.push(`${player.fullName}\n `)
      })
      return data ? data : 'No registered Player'
    }
},

]
