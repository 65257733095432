import React, { useContext, useEffect, useState } from "react";

import { IconButton, FormControl, FormHelperText, Box, TextField, CardContent, CardActions, Alert } from '@mui/material'

import { LoadingButton } from "@mui/lab";

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import { AdminContext } from "context/AdminContext";
import MyAccordion from "components/ui/accordion/MyAccordion";

import UserApi from "api/UserApi";

export function UserContact (props) {  
  const { setUser, user } = useContext(AdminContext)
  const [resError, setResError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState()
  const [editForm, setEditForm] = useState(false)
  const [contact, setContact] = useState(user.contact)

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })
  }
  
  const submit = async () => {
    setLoading(true)
    const id = contact._id
    const body = {
      contact: formData,
      email: formData?.email?.value,
      tel: formData?.tel?.value,
      password: formData.password
    }
    await UserApi.updateContact(id, body).then((res) => {      
      if (res.status === 200) {
        const newContact = res.data.contact;
        setUser((prevUser) => ({
          ...prevUser,
          contact: {
            ...prevUser.contact,
            ...newContact
          }
        }));        
      }
      if (res.status === 401) {
        setResError(res.data.msg)
      }
      
    }).catch((err) => {
      console.log(err)
    }).finally(() =>{
      setEditForm(false)      
      setLoading(false)
    })
    
  }

  useEffect((e) => {
    setFormData(contact)
  },[contact])


  return (              
    <MyAccordion title={'Contact Information'}>      

      {resError && <Alert severity="error" onClose={() => setResError(false)}>{resError}</Alert>}

    
      
    <IconButton 
      onClick={() => {setFormData(contact); setEditForm(!editForm)}} 
    >
          { editForm ? <EditOffIcon /> : <EditIcon /> }
      </IconButton>
    <CardContent>
    <Box 
      component='form'         
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}>

      <FormControl variant="standard" >
      <TextField 
        id={'email'}
        value={contact?.email?.value || contact?.email}
        disabled={true}
        label={'Email'}        
        variant={editForm ? 'outlined' : 'standard'}
        />
        <FormHelperText id="email">
          Email cannot be edited at this time.
        </FormHelperText>
        </FormControl>

      <FormControl >
      <TextField 
        id={'tel'}
        value={formData?.tel?.value || formData?.tel || ''}
        onChange={(e) => handleFormData(e)}
        label={'Phone Number'}
        type="number"                  
        disabled={!editForm}
        variant={editForm ? 'outlined' : 'standard'}
        />          
        <FormHelperText id="tel">
          Emergency use only.
        </FormHelperText>
        </FormControl>                            

        {editForm && 
          <TextField 
            variant="outlined"
            label={'Confirm Password'}
            id={'password'}        
            type={'password'}
            onChange={(e) => handleFormData(e)}          
          />}

      </Box>
      {(!formData?.password && editForm) && <Alert severity="info">Enter Password to submit</Alert>}
      </CardContent>
      {editForm && <CardActions>
          <LoadingButton
            variant="outlined"              
            disabled={!formData?.password}          
            loading={loading}
            onClick={submit}
            >
            Submit
          </LoadingButton>
        </CardActions>}    
      </MyAccordion>

    
  )
}