import React, { useEffect, useState, useContext } from "react";

import { AdminContext } from "context/AdminContext";
import { UserContext } from "context/UserContext";
import EventApi from "api/EventApi";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { CardActions, ButtonGroup, IconButton, Divider } from "@mui/material";

import InvitationResponses from "./rsvp/InvitationResponses";


const EventAttendance = (props) => {  
  const { user, profile } = useContext(AdminContext)
  const { setEvents, events } = useContext(UserContext)
  const { event } = props; 
  const [loading, setLoading] = useState(false)
  const [progressColor, setProgressColor] = useState()  
  
  const matchId = (people) => {
    let myPlayers = profile.flatMap(pro => pro.player);
    return myPlayers.some(obj1 => people.some(obj2 => obj1.id === obj2.id));
  }  

  const matchAndReplace = (arr, obj) => {
    const newArr = arr.map(item => item._id === obj._id ? obj : item);
    setEvents(newArr)
  };
  
  const handleInvitation = async (status) => {     
    setLoading(true)    
    const id = event._id
    const body = {user: user, action: status}    

    await EventApi.acceptEvent(id, body)
    .then((res) => {
      if (res.status === 200) {
        matchAndReplace(events, res.data.event)        
      }
    })
    setLoading(false)        
  }     

  useEffect(() => {
    matchId(event.attendees)
  }, [])

  return (<>

    <InvitationResponses event={event} />

    <CardActions sx={{p:0}}>
      <ButtonGroup variant="contained" size='large' sx={{width:'100%', alignItems:'center', justifyContent:'space-around', m: 0, p:1}}>

        <IconButton
        color='success'
        title='Attending'
        onClick={() => {handleInvitation('accept'); setProgressColor('success')}}   
        disabled={matchId(event.attendees)}
        ><CheckCircleIcon/></IconButton>        

        <IconButton 
          color='warning'
          title='Maybe'
          onClick={() => {handleInvitation('maybe'); setProgressColor('warning')}}
          disabled={matchId(event.invited)}
        ><QuestionMarkIcon/></IconButton>

        <IconButton
          color='error'
          title='Decline'
          onClick={() => {handleInvitation('decline'); setProgressColor('error')}}
          disabled={matchId(event.notAttending)}
        ><HighlightOffIcon/></IconButton>

      </ButtonGroup>
    </CardActions>

    {loading && <Box sx={{ width: '100%' }}>
      <LinearProgress color={progressColor} />
    </Box>}

  </>)
}

export default EventAttendance