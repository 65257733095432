import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

export default function BasicSingleSelect(props) {
  const { field, handleForm, data, readOnly } = props
  const [fieldData, setFieldData] = React.useState('');
  //const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setFieldData(event.target.value);
    handleForm(event)
  };

  React.useEffect(() => {
    if (data && data.length) {
      setFieldData(data)
    }
  },[data])


  return (
    
      <FormControl sx={{ m: 1, maxWidth: 500, width: '100%' }}>
        <InputLabel id={field._id}>{field.label}</InputLabel>
        <Select
          labelId={field._id}
          id={field._id}
          value={fieldData}
          label={field.label}
          name={field._id}
          onChange={(e) => handleChange(e)}
          disabled={readOnly}
        >
          <MenuItem value={null}>- Clear -</MenuItem>
          {field.options.map((opt, index) => {
            const display = opt.label || `${opt.division || ''} ${opt.name}`
            return (
              <MenuItem key={`${index}_${opt._id}`} value={opt._id}>{display}</MenuItem>
            )
          })}          
        </Select>
      </FormControl>
    
  );
}
