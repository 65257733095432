import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { useLocation, useNavigate } from 'react-router-dom';

import { AdminContext } from 'context/AdminContext';
import UserAccess from 'context/permissions/UserAccess';

export default function NativeNavigation(props) {
  const { currentOrg } = React.useContext(AdminContext)
  const [value, setValue] = React.useState(0);    
  const { pages } = props;
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (location.pathname.match('/user/') || location.pathname === '/notifications' ) {
      setValue(false)
    }
  }, [location])  
  
  return (
    <Box sx={{ width: '100%', position:'fixed', bottom:0, left:0, zIndex:9999 }}>
      <BottomNavigation
        //showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {pages && pages.map((page, i) => {
          return (
            <BottomNavigationAction key={i} label={page.text} onClick={() => {navigate(page.href)}} icon={page.icon} />
          )
        })}

        <UserAccess restriction={'team-assistant'}>
          <BottomNavigationAction label={'Admin'} onClick={() => {navigate(`${currentOrg.slug}/admin`)}} icon={<DashboardIcon />} />          
        </UserAccess>


        
      </BottomNavigation>
    </Box>
  );
}
