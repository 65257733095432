import api from "./_api";

class AuthApi {
   login (data) {    
    return api.post('auth/login', data)
  }

  logout () {
    return api.post('auth/logout')
  }

  isLoggedin () {
    return api.post('auth/verify')
  }  

  getAccessToken(id) {
    return api.post(`auth/accesstoken/${id}`)
  }

  updatePassword(data) {
    return api.put(`auth/updatepassword`, data)
  }

  forgotPassword(data) {
    return api.post(`auth/forgotpassword`, data)
  }

  resetPassword(token, data) {
    return api.post(`auth/resetpassword/${token}`, data)
  }

}

export default new AuthApi()

