import dayjs from "dayjs";

const numbers = Array.from({ length: 100 }, (_, index) => 0 + index);
const positions = ['Pitcher', 'Catcher', 'First Base', 'Second Base', 'Short Stop', ' Third Base', 'Left Fielder', 'Center Fielder', 'Right Fielder'];

export const teamColumns = [
//  {field: '_id', headerName: 'ID', width: 0},
 {field: 'fullName', headerName: 'Full Name', width: 150}, 
 {field: 'name', headerName: 'Name', width: 100}, 
 {field: 'division', headerName: 'Division', width: 75}, 
 {field: 'year', headerName: 'Year', width: 75}, 
 {field: 'season', headerName: 'Season', width: 75,
  valueGetter: (value ,row) => {      
    if (value) return value.charAt(0).toUpperCase() + value.slice(1);
  }
 },
 {field: 'sport', headerName: 'Sport', width: 150},
 {field: 'teamManager', headerName: 'Manager', width: 150, 
 valueGetter: (value ,row) => {            
      return value?.user?.fullName      
    }
 },
 {field: 'assistantManager', headerName: 'Assistants', width: 150,
  valueGetter: (value ,row) => {        
    return value?.user?.fullName
  }
 },
 {field: 'created', headerName: 'Created', width: 125,
    valueGetter: (value, row) => {
      return dayjs(value).format('YYYY-MM-DD', 'America/NewYork')
    }
  }
]

export const rosterColumns = [
  {field: 'fullName', headerName: 'Name', width: 100, 
    valueGetter: (value, row) => {
      return row?.player?.fullName
    }
  },  
  {field: 'number', headerName: 'Number', align:'center', width: 100, editable: true, type: 'singleSelect', valueOptions: numbers},
  {field: 'positionPrimary', headerName: 'Primary', width: 150, editable: true, type: 'singleSelect', valueOptions: positions},
  {field: 'positionSecondary', headerName: 'Secondary', width: 150, editable: true, type: 'singleSelect', valueOptions: positions},
  {field: 'division', headerName: 'Age/Division', width: 150,
  valueGetter: (value, row) => {
    return row?.player?.division
   }
  },
]


export const newTeamFormConfig = [
  {
    label: 'Team Name',
    _id: 'name',
    type: 'text',
    default: 'Team Name'      
  },   
  {
    label: 'Sport',
    _id: 'sport',
    type: "select",
    default: 'Sport',    
    options: [      
      {
        label: 'Softball fast-pitch',
        value: 'Softball-fast-pitch',
        _id: 'Softball-fast-pitch',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: 'Softball slow-pitch',
        value: 'Softball-slow-pitch',
        _id: 'Softball-slow-pitch',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: 'Baseball',
        value: 'Baseball',
        _id: 'Baseball',
        access:  ['dev', 'director', 'staff', 'team-manager']
      }
    ]
  },
  {
    label: 'Division',
    _id: 'division',
    type: 'select',
    default: 'Division',
    options: [
      {
        label: 't-ball',
        value: 't-ball',
        _id: 't-ball',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '6u',
        value: '6u',
        _id: '6u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '8u',
        value: '8u',
        _id: '8u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '9u',
        value: '9u',
        _id: '9u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '10u',
        value: '10u',
        _id: '10u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '11u',
        value: '11u',
        _id: '11u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '12u',
        value: '12u',
        _id: '12u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '14u',
        value: '14u',
        _id: '14u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },      
      {
        label: '16u',
        value: '16u',
        _id: '16u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: '18u',
        value: '18u',
        _id: '18u',
        access:  ['dev', 'director', 'staff', 'team-manager']
      }
    ]
  },
  {
    label: 'Season',
    _id: 'season',
    default: 'Season',
    type: 'select',
    options: [
      {
        label: 'Spring',
        value: 'spring',
        _id: 'spring',
        access: ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: 'Fall',
        value: 'fall',
        _id: 'fall',
        access: ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: 'Summer',
        value: 'summer',
        _id: 'summer',
        access: ['dev', 'director', 'staff', 'team-manager']
      }
    ]
  },
  {
    label: 'Year',
    type: 'date',
    _id: 'year',
    default: 'year',    
  }
  
]


