import React, { useState, useContext, useEffect } from "react";

import { TeamContext } from "context/TeamContext";
import TeamApi from "api/TeamApi";

import { Card, CardContent, Stack, CardActions, Box, FormGroup, ButtonGroup, TextField } from "@mui/material";
import { CardHeader, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";


import FormTextField from "components/ui/form/components/FormTextField";
import BasicSingleSelect from "components/ui/form/components/BasicSingleSelect";
import MyDatePicker from "components/ui/form/components/DatePicker";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import EditRoster from "./roster/EditRoster";
import Roster from "./roster/Roster";

import { newTeamFormConfig } from "./TeamsConfig";;

const NewTeamForm = (props) => {  
  const {data, handleCancel} = props  
  const { getTeams, asstMan, teamMan, updateTeam } = useContext(TeamContext)    

  const [awaiting, setAwaiting] = useState(false)
  
  // Are we filling out a new team?
  const [isNew, setIsNew] = useState(true)
  // Editing team information ?
  const [allowEdit, setAllowEdit]   = useState(false)  
  const [showRoster, setShowRoster] = useState(false)
  
  // Form State        
  const [formData, setFormData] = useState()  
  const [teamDate, setTeamDate] = useState()  
  
  // Input value setters that work with ids
  const [teamId, setTeamId] = useState()  
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [selectedAssistants, setSelectedAssistants] = useState()

  const handleForm = (e) => {        
    let target = e.target.id || e.target.name             
    setFormData({
      ...formData,         
      year: teamDate,       
      [target] : e.target.value
    })                   
  }

  const handleSubmit = async () => {
    setAwaiting(true)
    await TeamApi.create(formData)
    .then(async (res) => {
      await getTeams()      
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setAwaiting(false)
      handleCancel()
    })
  }

  const handleEdit = async () => {
    setAwaiting(true)    
    const id = data[0]._id
    const update = await updateTeam(id, formData)
    if (update) setAwaiting(false)
    handleCancel()    
  }
  
  const handleCloseForm = () => {
    handleCancel(false)
  }   

  const handleSelectedTeam = () => {     
    let play = []; let assT = []
    setTeamId(data[0]._id)
    data.forEach((el) => { 
      el?.players?.forEach((player) => {        
        play.push(player._id)
      })              
    })
    setSelectedPlayers(play)     
  }     
  
  useEffect(() => {    
    if (data && data.length) {
      setAllowEdit(true)           
      setIsNew(false)
      handleSelectedTeam(data)
      setFormData(data[0])
    }
  },[data])  
  
  return (
    
    <Card>
      <CardActions>
        <ButtonGroup>
          <Button variant="outlined" onClick={handleCloseForm}>Back</Button>          
          {!isNew && !showRoster && <Button variant='outlined' onClick={() => setAllowEdit(!allowEdit)}>{!allowEdit ? 'cancel edit' : 'edit'}</Button>}
          {!isNew && allowEdit && <Button variant="outlined" onClick={() => setShowRoster(!showRoster)}>{!showRoster ? 'Roster' : 'Exit Roster'}</Button>}
        </ButtonGroup>      
      </CardActions>
      <CardHeader title={!isNew ? `${teamDate || formData.year} ${formData.division} ${formData.name}` :'Create a new team.'} />
            
      {!showRoster && <>
        <CardContent style={{display: 'block'}}>
          <FormGroup>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}      
          >
            {newTeamFormConfig && newTeamFormConfig.map((field) => {
              if (field.type === 'text') {                
                return ( 
                  <FormTextField   
                    key={field._id}
                    field={field}
                    handleForm={handleForm} 
                    data={formData && formData[field._id]}
                    readOnly={allowEdit}
                    required
                  />           
                )
              } else if (field.type === 'date') {
                return (
                  <MyDatePicker 
                  key={field._id}
                  field={field}
                  format={'YYYY'}
                  view={['year']}
                  handleDate={setTeamDate}  
                  data={formData && formData[field._id]}        
                  readOnly={allowEdit}  
                  />
                )
              
              } else if (field.type ==='select') {                
                return (
                  <BasicSingleSelect 
                    key={field._id}
                    field={field}
                    handleForm={handleForm}
                    multiple={false}
                    data={formData && formData[field._id]}
                    readOnly={allowEdit}
                    required
                  />
                )
              }
            })}         


              <TextField
                select
                sx={{m:2, ml:0, maxWidth: '500px' }}   
                id={'teamManager'}              
                name={'teamManager'}
                label={'Team Manager'}
                value={formData?.teamManager?.id || formData?.teamManager || ""}                     
                onChange={(e) => handleForm(e)}                                   
                disabled={allowEdit}
                required
                >
                <MenuItem value={null}>None selected</MenuItem>
                {teamMan && 
                  teamMan.map((manager) => {
                    return (
                      <MenuItem key={manager._id} value={manager._id}>
                        {manager.user.fullName}
                      </MenuItem>
                  )            
                })}               
              </TextField>

            {asstMan && 
            <TextField
              select
              sx={{m:2, ml:0, maxWidth: '500px' }}   
              id={'assistantManager'}
              key={`assistantManager_key`}                
              name={'assistantManager'}
              label={'Assistant Manager'}
              value={formData?.assistantManager?.id || ""}                     
              onChange={(e) => handleForm(e)}                                   
              disabled={allowEdit}
              required
            >
            <MenuItem value={null}>None selected</MenuItem>
          {asstMan.map((manager) => {
            return (
            <MenuItem key={manager.id} value={manager.id}>
              {manager.user.fullName}
            </MenuItem>)
            
          })}
    
          </TextField>}    

          </Box>      
        </FormGroup>
        </CardContent>

        <CardActions>
          <Stack spacing={2} direction="row">       
            <ButtonGroup>
              <LoadingButton 
                variant="outlined"
                loading={awaiting}
                disabled={allowEdit || !formData}
                onClick={isNew ? handleSubmit : handleEdit}          
              >
                Submit
              </LoadingButton>            
            </ButtonGroup>     
          </Stack>
        </CardActions>
      </>}
      
      {!isNew && allowEdit && showRoster &&
        <Roster selectedPlayers={selectedPlayers} teamId={teamId} />
      }
      {!isNew && allowEdit && showRoster &&
        <EditRoster teamId={teamId} handleSelectedTeam={handleSelectedTeam}/>
      }

      
    </Card>
  )
}

export default NewTeamForm
