import React from "react";

import { Card, CardHeader, CardMedia, Stack, Avatar, IconButton } from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';


const TeamListItem = (props) => {
  const { team, handleSelectedTeam } = props     
  
  return (
    <React.Fragment>

     <Card 
      key={team.id} 
      sx={{mb: 2, cursor:'pointer'}}
      onClick={() => handleSelectedTeam(team)}       
    >                   
      <CardHeader      
        action={
          <IconButton onClick={() => handleSelectedTeam(team)}>
            <SportsBaseballIcon />
          </IconButton>
        }   
        avatar={<Avatar src={`/images/${team.logo}`}/>}
        titleTypographyProps={{variant:'h6' }}
        title={team.fullName}
        subheader={team.sport} 
        sx={{width: '100%'}} 
      />
      
    </Card> 
    </React.Fragment>
  
  )
}

export default TeamListItem