const adminNav = [
  {
    text: 'Organizations',
    href: './organization',
    access: 'staff'
  },  
  {
    text: 'Members',
    href: './members',
    access: 'team-manager'
  },
  {
    text: 'Teams',
    href: './teams',
    access: 'team-assistant'
  },
  {
    text: 'Schedule',
    href: './schedules',
    access: 'team-assistant'
  },
  {
    text: 'Exit Dashboard',
    href: `/`,
    access: 'user'
  }
]

export default adminNav