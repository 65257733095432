import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { Container, Step, StepLabel, Stepper } from "@mui/material";

import ResponsiveAppBar from "components/ui/navigation/ResponsiveAppBar";


// if new user
import NewUserRegistrationPage from "./NewUserRegistration";

// register players
import PlayerRegistration from "./regComponents/PlayerRegistration";
import Finished from "./regComponents/Finished";



const RegistrationIndex = () => {    
  const { token, returning } = useParams()  
  const [ user, setUser ] = useState()
  const [ players, setPlayers ] = useState([])

  const [activeStep, setActiveStep] = useState(0) 

  const steps = ['User Registration', 'Player Registration']

  const nextStep = () => {
    if (activeStep <= steps.length) {
      setActiveStep((currentStep) => currentStep + 1)
    }
  }

  useEffect(() => {
    document.body.classList.add('registration')
    return () => {
      document.body.classList.remove('registration')
    }
  },[])

  return (
    <>
    <ResponsiveAppBar />  
    <Container sx={{mt: 2, mb: 5, maxWidth:"xl" }}>
    {activeStep == 0 && 
      <NewUserRegistrationPage
        regUser={setUser}
        nextStep={nextStep}      
      />}
    {activeStep == 1 &&
      <PlayerRegistration
        players={players}
        setPlayers={setPlayers}
        user={user}
        nextStep={nextStep}
      />}    
    {activeStep == 2 &&
      <Finished />}    

    <Stepper
      alternativeLabel
      orientation="horizontal" 
      activeStep={activeStep} 
      sx={{my:4}}>      
    {steps.map((step, index) => {
      return (
      <Step key={index}>       
        <StepLabel title={step} sx={{display: {xs: 'none', md: 'flex'} }}>
           {step}
        </StepLabel>       
        <StepLabel title={step} sx={{display: {xs: 'flex', md: 'none'} }}></StepLabel>
      </Step>      
      )
    })}      
    </Stepper>          

    </Container>
    <Outlet />
  
    </>
  )
}



export default RegistrationIndex