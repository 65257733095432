import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitch } from 'context/ThemeContext';
import { AppLoadProvider } from 'context/AppLoadContext';
import { AdminProvider } from 'context/AdminContext'
import { AuthContextProvider } from 'context/AuthContext';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeSwitch>
        <AppLoadProvider>                
          <AdminProvider>
            <AuthContextProvider>
              <App />            
            </AuthContextProvider>
          </AdminProvider>
        </AppLoadProvider>
      </ThemeSwitch>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
