import api from "./_api";

class EventApi {
  get() {
    return api.get('/event')
  }
  getTeamEvents(id) {
    return api.get(`/event/team/${id}`)
  }
  create(data) {
    return api.post('/event', data)
  }
  update(id, data) {
    return api.put(`/event/${id}`, data)
  }
  delete(id) {
    return api.delete(`/event/${id}`)
  }
  acceptEvent(id, userId) {
    return api.put(`/event/accept/${id}`, userId)
  }
}

export default new EventApi()