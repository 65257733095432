export const newPlayerForm = [
  {
    label: 'First name',
    _id: 'firstName',
    type: 'text'
  },
  {
    label: 'Last name',
    _id: 'lastName',
    type: 'text'
  },
  {
    label: 'Birth year',
    _id: 'dob',
    type: 'date'
  }
]

export const playerColumns = [
  {field: 'firstName', headerName: 'First Name', width: 100},
  {field: 'lastName', headerName: 'Last Name', width: 100},
  {field: 'dob', headerName: 'DOB', width: 100},
  {field: 'division', headerName: 'Age', width: 75},
  {field: 'team', headerName: 'Team', width: 200,
    valueGetter: (value ,row) => {
      let temp= [];      
      value?.forEach((team) => {
        let data = `${team.fullName}\n `
        temp.push(data)
      })
      return temp
    }
  },
  {field: 'guardians', headerName: 'Guardians', width: 150,
    valueGetter: (value ,row) => {      
      let temp= [];
      value?.forEach((person) => {
        let data = `${person?.firstName} ${person?.lastName}`
        temp.push(data)
      })
      return temp
    }
  },
  {field: 'guardians.tel', headerName: 'Emergency Numbers', width: 200, 
    valueGetter: (value, row) => {
      let nums = []
      row?.guardians?.forEach((guard) => {
        nums.push(`${guard?.contact?.tel?.value} \n`)
      })
      return nums
    }
  }
]