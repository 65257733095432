import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import {
  Link,
  Outlet,
} from 'react-router-dom';


function MyTabs(props) {
  const { tabs } = props;
  const [currentTab, setCurrentTab] = React.useState(tabs[0].link)  
  

  return (
    <Tabs 
      variant='scrollable'
      allowScrollButtonsMobile
      value={currentTab}
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
      }}
    >
      {tabs.map((link, index) => {
        return (
          <Tab key={index} label={link.label} value={link.link} to={link.link} component={Link} onClick={() => setCurrentTab(link.link)}/>  
        )
      })}
    </Tabs>
  );
}

export default function TabsRouter(props) {
  const { tabs } = props
  return (
    <>
     <Box sx={{ my: 2, width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <MyTabs tabs={tabs} />
        </Box>
     </Box>
      <Outlet />    
    </>
  );
}
