import React from "react";
import { Card, Divider } from "@mui/material";

import PlayerProfile from "./PlayerProfille";
import { UserProfile } from "./UserProfile";

const Profile = () => { 
  
  return (
    <Card>            
      <UserProfile />
      <Divider />
      <PlayerProfile/>      
    </Card>
  )
}
export default Profile

