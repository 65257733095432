import React, { useContext, useState } from "react"
import { IconButton, Box, CardActions, CardContent, Alert, TextField } from '@mui/material'
import { LoadingButton } from "@mui/lab";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import { AdminContext } from "context/AdminContext";
import { AuthContext } from "context/AuthContext";
import MyAccordion from "components/ui/accordion/MyAccordion";
import AuthApi from "api/AuthApi";



export function PasswordChange () { 
  const { user } = useContext(AdminContext)
  const { logout } = useContext(AuthContext)
  const [editForm, setEditForm] = useState(false)
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState(false)

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })    
  }

  const submit = async (e) => {
    setLoading(true)
    setFormData({...formData, user})
    await AuthApi.updatePassword(formData)
    .then((res) => {
      if (res.status === 200) {
        setMsg({severity:'success', msg:'Password Changed!'})
      }
    })
    .catch((err) => {
      setMsg({severity:'error', msg:'Something went wrong.'})
    })
    .finally(() => {
      setLoading(false)      
      logout()
    })
  }

  const allowSubmit = formData?.passwordCurrent &&
    (formData?.password?.length >= 8 && formData?.passwordConfirm?.length >= 8) &&
    (formData?.password === formData?.passwordConfirm) 

  return (<>
  <MyAccordion title='Change Password'>     

    <IconButton 
      onClick={() => { setFormData({}); setEditForm(!editForm) }} 
    >
      {editForm ? <EditOffIcon /> : <EditIcon /> }
    </IconButton> 

    <CardContent>
      <Box
        component='form'         
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}        
        onChange={(e) => {handleForm(e)}}        
      >
        <TextField 
          id='passwordCurrent'
          value={formData?.passwordCurrent || ''}
          label={'Current password'}
          type='password'          
          disabled={!editForm}
          variant={editForm ? 'outlined' : 'standard'}

        
          />
        <TextField 
          id='password'
          value={formData?.password || ''}
          label={'New password'}
          type='password'
          disabled={!editForm}
          helperText='Password minimum is 8 chars'
          variant={editForm ? 'outlined' : 'standard'}

          />
        <TextField 
          id='passwordConfirm'
          value={formData?.passwordConfirm || ''}
          label={'Confirm new password'}
          type='password'
          disabled={!editForm}
          variant={editForm ? 'outlined' : 'standard'}
          />            
      </Box>
  </CardContent>

  {editForm && <>
    <CardActions>
      <LoadingButton
        variant="outlined"  
        onClick={submit}       
        loading={loading}  
        disabled={!allowSubmit}
        >Submit </LoadingButton>
    </CardActions>
    {msg && 
    <Alert severity={msg.severity} onClose={(() => setMsg(false))}>
      {msg.msg}
    </Alert>}
    <Alert severity="info">
      You will be logged out and will need to log back in to continue.
    </Alert></>}

  </MyAccordion>
  </>)
}
