import React, {useState, useContext} from "react";

import {Divider, Button, CardActions, CardMedia, CardHeader, CardContent, Box, Tabs, Tab, Typography, Stack, Avatar } from '@mui/material'
import PropTypes from 'prop-types';

import MyAccordion from "components/ui/accordion/MyAccordion";
import { AdminContext } from "context/AdminContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export function UserOrgs (props) {
  const { profile } = useContext(AdminContext)
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <MyAccordion title={'Organization Information'}>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Manage members"
            variant="scrollable"
          >          
            {profile.map((pro, i) => {
              return (
                <Tab label={pro?.organization?.shortName} key={i} {...a11yProps(i)} />
              )
            })}
          </Tabs>
        </Box>

        {profile.map((pro, i) => {
          return (
            <CustomTabPanel value={value} index={i} key={i}>
              
              <CardHeader 
                avatar={
                  <Avatar 
                    src={`/images/${pro.organization.logo}`} 
                    alt={pro?.organization?.name} 
                  />
                }
                title={pro?.organization?.name}
                titleTypographyProps={{variant:'h6' }}                
              />              

              <CardContent>
                {pro?.organization?.contact?.fullAddress} <br />
                {pro?.organization?.contact?.tel?.value}
              </CardContent>

              <Stack direction={'column'} sx={{alignItems: 'start', justifyContent: 'start' , flexWrap:'wrap'}} >
              
                <Divider />

                {pro?.player?.length > 0 && 
                  <CardHeader subheader={`Registered Player${pro?.player?.length > 1 ? 's': ''}`} />}

                <Stack direction={'row'} sx={{alignItems: 'start', justifyContent: 'start' , flexWrap:'wrap'}}>
                  {pro?.player?.map((p, index) => {
                    return (
                      <CardContent key={`${index}_player`} >
                        <>{p.fullName}</>                      
                      </CardContent>
                      )
                    })}
                </Stack>             
              
                <Divider />
                {pro?.team?.length > 0 && <CardHeader subheader={`Registered Team${pro?.team?.length > 1 ? 's': ''}`} />}

                <Stack direction={'row'} sx={{alignItems: 'start', justifyContent: 'start' , flexWrap:'wrap'}}>
                  {pro?.team?.map((t, index) => {
                    return (
                      <CardContent key={`${index}_team`}>
                        {t?.fullName} <br />
                        {t?.sport}
                      </CardContent>
                    )
                  })}
                </Stack>
              
              </Stack>

              <CardActions>
                <Button color='error' variant="outlined" disabled>Leave Org</Button>
              </CardActions>

            </CustomTabPanel>
          )
        })}
      </Box>

    </MyAccordion>
  )
}