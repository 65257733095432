import api from "./_api";

class TeamApi {    
  get() {
    return api.get('/team')
  }
  getManagers(id) {
    return api.get(`/team/assistantmanagers/${id}`)
  }
  getPlayersFromTeam(teamId) {
    return api.get(`/team/players/${teamId}`)
  }
  allTeamsForSchedule() {
    return api.get('/team/scheduling')
  }
  create(data) {
    return api.post('/team', data)
  }
  update(id, data) {
    return api.put(`/team/${id}`, data)
  }
  addPlayer(id, player) {
    return api.put(`/team/addplayer/${id}`, player)
  }
  removePlayer(id, player) {
    return api.put(`/team/removeplayer/${id}`, player)
  }
  delete(id) {
    return api.delete(`/team/${id}`)
  }
}

export default new TeamApi()