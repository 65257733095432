import api from "./_api";

class VenueApi {
  get() {
    return api.get('venue')
  }
  getOne(id) {
    return api.get(`venue/${id}`)
  }
  create(data) {
    return api.post(`venue`, data)
  }
  update(data) {
    return api.put(`venue`, data)
  }
  delete(id) {
    return api.delete(`venue/${id}`)
  }
}

export default new VenueApi;