import React, { useState, useContext, useEffect } from "react";

import { TextField, CardActions, Card, CardHeader, CardContent, Box, Tabs, Tab, Alert, ListItem } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload';


import { LoadingButton } from "@mui/lab";
import { YoutubeFrameGetter } from "components/ui/media/YoutubeFrameGetter";

import UserApi from "api/UserApi";
import PropTypes from 'prop-types';
import { AdminContext } from "context/AdminContext";
import { UserContext } from "context/UserContext";
import MyAccordion from "components/ui/accordion/MyAccordion";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const PlayerProfile = () => {
  const { user, setUser } = useContext(AdminContext)
  const { getInitialData } = useContext(UserContext)  
  const [loading, setLoading] = useState(false)

  // Form  
  const [formData, setFormData] = useState()

  // Tabs
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);        
  };  

  const handleForm = (e) => {
    setFormData({
      ...formData,   
      email: user.contact.email.value,  
      [e.target.id] : e.target.value 
    })    
  }

  const setWalkUp = (data) => {
    setFormData({
      ...formData,      
      walkup: data,      
    })            
  }

  const handleSubmit = async () => {    
    setLoading(true)    
    await UserApi.updatePlayer(formData._id, formData)
    .then((res) => {
      if (res.status === 200) {        
        const updatedPlayer = res.data.player;
        const newPlayers = user.player.map(player =>
          player.id === updatedPlayer.id ? updatedPlayer : player
        );
        setUser({
          ...user,
          player: newPlayers
        })
        getInitialData()
      }
    })    
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {      
      setLoading(false)
    })
  }

  useEffect(() => {
    setFormData(user?.player[value])        
  }, [value])

  useEffect(() => {    
    setFormData(user?.player[value])
  }, [user])
  
  return (<>    
      <CardHeader subheader={`Player${user?.player?.length > 0 ? 's': ''}`} />

      <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="User Players"  
          variant="scrollable"
        >          
          {user?.player.map((play, i) => {
            return (
              <Tab label={play.fullName} key={i} {...a11yProps(i)} />
            )
          })}
        </Tabs>
      </Box>
      {user?.player.map((play, i) => {
        return (
        <CustomTabPanel value={value} index={i} key={i} >
          
          <CardContent>                             
            
            <YoutubeFrameGetter 
              data={formData?.walkup} 
              setWalkUp={setWalkUp}
            />

            <Box 
              component='form'             
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                display:{sm:'block', md:'flex'}
              }}
              >      

              <TextField 
                variant="outlined"
                label={'Confirm Password'}
                id={'password'}        
                type={'password'}
                onChange={(e) => handleForm(e)}
                />                       
              </Box>
              
          </CardContent>            

          <CardActions>
            <LoadingButton
              variant="outlined"  
              disabled={!formData?.walkup || !formData.password}          
              loading={loading}      
              onClick={handleSubmit}        
              >
              Submit
            </LoadingButton>
          </CardActions>

        </CustomTabPanel>
      )
        
      })}
    </Box>

    <MyAccordion title={'Instructions'}>
      <Alert severity="info">
        <ListItem>Visit www.youtube.com and search for your favorite song.</ListItem>
        <ListItem>Click on share and copy url.</ListItem>
        <ListItem>Past url in the "Youtube share url" box.</ListItem>
        <ListItem>If you prefer the song to start at a certain time, enter it in the "Song start min/sec" box "mm:ss". Example "01:30" starts song at Minute 1 and 30 seconds.</ListItem>
        <ListItem>Press the red upload icon <UploadIcon /> </ListItem>
        <ListItem>You should now have a preview.</ListItem>
        <ListItem>Enter your password and submit to save.</ListItem>
      </Alert>
      </MyAccordion>


  </>)
}


export default PlayerProfile