import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import ChatIcon from '@mui/icons-material/Chat';


const mainNav = [
  // {
  //   text: 'Home',
  //   href: './',
  //   access: 'user'
  // },
  {
    text: 'Schedule',
    href: '../',
    access: 'user',
    icon: <CalendarMonthIcon />
  }, 
  {
    text: 'Teams',
    href: './teams',
    access: 'user',
    icon: <SportsBaseballIcon />
  },    
  {
    text: 'Chats',
    href: '../rooms',
    access: 'user',
    icon: <ChatIcon/>
  }  
];

export default mainNav