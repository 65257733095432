import api from "./_api";

class DownloadApi {
  downloadApp(app) {
    return api.get(`/downloads/app/${app}`, {
      responseType: 'blob'
    })
  }
}

export default new DownloadApi();