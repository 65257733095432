import React from "react";

import { Stack, AvatarGroup, Avatar, Badge } from "@mui/material";
import { styled } from '@mui/material/styles';

export function ChatAvatarGroup(props) {
  const { roomUsers } = props;  
  const { absent, connected } = roomUsers;  

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,        
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <Stack direction={'row'} sx={{position: 'sticky', top: '10px', left: 5, zIndex:999}}>

      <AvatarGroup max={4}>
      {connected && connected.map((user, index) => {
        return (
          <StyledBadge
            key={index}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <Avatar 
              sx={{width: 24, height:24}} 
              alt={user.fullName} 
              src={user.avatar} 
              title={user.fullName}      
            />
          </StyledBadge>
        )
      })}
      {absent && absent.map((user, index) => {
        return (        
          <Avatar 
            key={`${index}_avatar`} 
            sx={{width: 24, height:24}} 
            alt={user.fullName} 
            src={user.avatar} 
            title={user.fullName}             
          />            
        )
      })}          
      </AvatarGroup>

  </Stack>        

  )
}