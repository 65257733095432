import React, {createContext, useEffect, useContext, useState} from "react";

import { AdminContext } from "./AdminContext";
import { AppLoadContext } from "context/AppLoadContext";

import Auth from "api/AuthApi";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext(false)

export const AuthContextProvider = ({ children }) => {
  const {setUser, setUserRole, response, setResponse, setCurrentOrganization} = useContext(AdminContext)    
  const { setAppLoading } = useContext(AppLoadContext)
  const [loginError, setLoginError] = useState(false)
  const navigate = useNavigate();

  const login = async (data) => {        
    await Auth.login(data).then((res) =>{        
      if (res.status === 401) {
        setLoginError(res.data.msg)
      }
      if (res.status === 200) {
        setResponse([res.data.profile])    
        setCurrentOrganization(res.data.profile[0])        
      }
    }).catch((err) => {      
      console.log(err)
    }).finally(() => {
      setAppLoading(false)
      navigate('/')
    })           
  }

  const getCurrentUser = async () => {    
    setAppLoading(true)                    
    await Auth.isLoggedin().then((res) => { 
      if (res && res.status == 200) {
        setResponse([res.data.profile]) 
        setCurrentOrganization(res.data.profile[0])                  
      }        
    }).catch((err) => {            
      console.log(err)      
    }).finally(() => {              
      navigate('/')
      setAppLoading(false)                  
    }) 
  }  


  const logout = async () => {    
    await Auth.logout().then((res) => {
      if (res.status === 200) {
        setUser(false)      
        setResponse(false)      
        setUser(false)
        setUserRole(false)      
      }
    }).then(() => {            
      navigate('/login')            
    }).catch((err) => {
      console.log(err)      
    })
  }

  useEffect(() => {            
    if (  window.location.pathname.includes('/registration/') ||
          window.location.pathname.includes('/resetpassword/') 
        ) { return }     
    else { getCurrentUser() }    
  }, []) 
  

  const value = {    
    login,    
    logout,
    loginError,
    setLoginError,
    getCurrentUser
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}

