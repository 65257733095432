import api from "./_api";

class InvtApi {  
  create(data) {
    return api.post('invitation', data)
  }
  resend(id) {
    return api.post(`invitation/${id}`)
  }
  get() {
    return api.get('invitation/')
  }
  update(id, data) {
    return api.put(`invation/${id}`, data)
  }
  delete(id) {
    return api.delete(`invitation/${id}`)
  }
}

export default new InvtApi()