import React, {useContext, useEffect, useState} from "react";
import { AdminContext } from "context/AdminContext";

const roles = [
  {
    role: 'dev',
    access: ['dev', 'director', 'staff', 'team-manager', 'team-assistant', 'user']
  },
  {
    role: 'director',
    access: ['director', 'staff', 'team-manager', 'team-assistant', 'user']
  }, 
  {
    role: 'staff',
    access: ['staff', 'team-manager', 'team-assistant', 'user']
  },
  {
    role: 'team-manager',
    access: ['team-manager', 'team-assistant', 'user']
  },
  {
    role: 'team-assistant',
    access: ['team-assistant', 'user']
  },
  {
    role: 'user',
    access: ['user']
  }
]

const UserAccess = (props) => {  
  const { userRole } = useContext(AdminContext)  
  const { restriction } = props
  const [access, setAccess] = useState(false)    

  const checkPriviledge = (userRole) => {
    roles.forEach((role) => {   
      const user = userRole?.includes(role.role)      
      const userAccess = role.access.includes(restriction)       
      if (user && userAccess) setAccess(true) 
      if (user && !userAccess) setAccess(false)
    })
  }

  useEffect(() => {
    if (restriction && userRole) {
      checkPriviledge(userRole)  
    } 
  }, [userRole])

  if (access) return ( <> {props.children} </>)  
}

export default UserAccess



