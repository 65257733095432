import React, { useContext } from "react";
import dayjs from "dayjs";

import { ListItem, Stack, Avatar, Typography, Paper, CardHeader } from "@mui/material";
import { AdminContext } from "context/AdminContext";

export function ChatMessage (props) {
    const { msg } = props
    const { user } = useContext(AdminContext)

    const style = {                       
        overflowWrap: 'break-word',
        wordWrap: 'break-word',   
        wordBreak: 'break-all',  
        wordBreak: 'break-word',  
        hyphens: 'auto',
        textAlign: 'left'
      }      

    const isUser = () => {        
        const id = msg.user.id
        if (user.id === id) { return true }
        else { return false}
    }  

    return (
        <ListItem 
            sx={{
                p:0,
                my:2,
                justifyContent: isUser() ? 'end' : 'start'
            }}
        >
            <Stack 
                direction={isUser() ? 'row-reverse' : 'row'} 
                sx={{
                    alignItems:'start'                    
                }}
            >
                
                <Stack 
                    direction={'column'} 
                    sx={{
                        alignItems: isUser() ? 'end' : 'start',
                        justifyContent:'start'                        
                    }}                
                >
                    <CardHeader 
                        sx={{
                            fontSize: '0.8rem',  
                            px:0                              
                        }}
                        avatar={<Avatar src={msg.user.avatar} />}
                        title={msg.user.fullName}                             
                        subheader={dayjs(msg.timestamp).format('h:mm a')}
                    />                    

                        <Paper 
                            sx={{                                                                
                                margin: isUser() ? '0 2rem 0 0' : '0 0 0 2rem', 
                                borderRadius: '16px',
                                minWidth: '100px',
                                textAlign: 'left',
                                p:1.5
                            }}
                        >
                            <Typography 
                                variant="body" 
                                sx={style}
                            >
                                {msg.message}
                            </Typography>
                        </Paper>                    

                </Stack>
            </Stack>
        </ListItem>
    )
}