export const userConfig = [
  {
    field: 'First Name',
    id: 'firstName',
    type: 'text'
  },
  {
    field: 'Last Name',
    id: 'lastName',
    type: 'text'
  },
  {
    field: 'Tel',
    id: 'tel',
    type: 'number'    
  },    
  {
    field: 'Email',
    id: 'email',
    type: 'email',
    readOnly: true
  }  
]

export const userPassword = [
  {    
    field: 'Password',
    id: 'password',
    type: 'password'    
  },
  {
    field: 'Confirm Password',
    id: 'passwordConfirm',
    type: 'password' 
  }
]

export const newPlayerForm = [
  {
    field: 'First name',
    id: 'firstName',
    type: 'text'
  },
  {
    field: 'Last name',
    id: 'lastName',
    type: 'text'
  },
  {
    field: 'Birth year',
    id: 'dob',
    type: 'date'
  }
]