import React, { useContext, useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Card, Box, Button, ButtonGroup, CardActions, CardContent, CardHeader, FormGroup, Stack, TextField } from "@mui/material";
import MyCard from "components/ui/card/MyCard";

import { TeamContext } from "context/TeamContext";
import { VenueContext } from "context/VenueContext";
import { EventContext } from "context/EventContext";

import MyDateTimePicker from "components/ui/form/components/DateTimePicker";
import BasicSingleSelect from "components/ui/form/components/BasicSingleSelect";

import ScheduleGames from "./ScheduleGame";
import { eventType } from "./ScheduleConfig";

const ScheduleForm = (props) => {
  const { handleOpenForm, data } = props
  const { team } = useContext(TeamContext)
  const { venues } = useContext(VenueContext)
  const { newEvent, loading, editEvent, 
    allTeamsForSchedule, eventTeams } = useContext(EventContext)
  const [formData, setFormData] = useState(false)  
  
  const [editingMode, setEditMode] = useState(false)  
  const [ isNew, setIsNew ] = useState(true)

  const handleForm = (e) => {
    let target = e.target.id || e.target.name
    if (target === 'type') {return setFormData({ [target]: e.target.value })}
    setFormData({
      ...formData,      
      [target] : e.target.value
    })            
  }

  const handleSetDate = (date) => {
    setFormData({
      ...formData,
      date: date
    })
  }

  const handleSubmit = () => {
    newEvent(formData)
    handleOpenForm()
  }

  const handleEdit = () => {
    editEvent(formData)
    setEditMode(false)
    handleOpenForm()
  }


  useEffect(() => {
    if (data) {          
      setFormData(data[0])
      setIsNew(false)
      setEditMode(true)
    }
  }, [data])

  useEffect(() => {
    allTeamsForSchedule()
  }, [])

  const homeList = {
    label: 'My Team',
    _id: 'homeTeam',
    options: team
  }  

  const venueList = {
    label: 'Venue',
    _id: 'venue',
    options: venues
  }

  const isGame = formData?.type === 'game' || formData?.type === 'scrimmage';
  const needField = formData?.type === 'game' || formData?.type === 'scrimmage' || formData?.type === 'practice';
  const disabled = !formData.type || !formData?.homeTeam || !formData?.date;


  return (
    <Card>
      <CardActions>
        <ButtonGroup>          
          <Button variant="outlined" onClick={handleOpenForm}>back</Button>
          {data && 
            <Button variant="outlined" onClick={() => setEditMode(!editingMode)}>
              {editingMode ? 'Edit' : 'cancel edit'}
            </Button>}
        </ButtonGroup>
      </CardActions>
      <CardHeader title={!isNew ? `Edit Event` : 'New Event'} />
      <CardContent style={{display: 'block'}}>
        <FormGroup>
        <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}      
            >
          {eventType && <BasicSingleSelect 
            field={eventType}
            multiple={false}
            data={formData?.type || ''}
            state={['']}
            handleForm={handleForm}                          
            readOnly={editingMode}
            required
          />}

          {homeList && <BasicSingleSelect 
            field={homeList}            
            data={formData?.homeTeam?.id || ' '}
            state={['']}
            handleForm={handleForm}  
            readOnly={editingMode}                                      
            required
          />}

          <MyDateTimePicker 
            label={'Date and Time'}
            setter={handleSetDate}
            data={formData?.date}
            readOnly={editingMode}
          />      

          {isGame && 
            <ScheduleGames
              handleForm={handleForm}
              team={eventTeams}
              data={formData}
              readOnly={editingMode}
            />}

          {needField && <TextField 
            sx={{ m: 1, maxWidth: 500, width: '100%' }}
            variant="outlined"
            type='text'
            label="Notes"
            id="notes"
            disabled={editingMode}
            value={formData?.notes || ''}
            onChange={(e) => handleForm(e)}
          />}

          {venues && 
            <BasicSingleSelect 
              sx={{ m: 1, maxWidth: 500, width: '100%' }}
              variant="outlined"
              field={venueList}
              data={formData?.venue?._id || ' '}
              handleForm={handleForm}
              readOnly={editingMode}
            />}

          <TextField 
            sx={{ m: 1, maxWidth: 500}}
            variant="outlined"            
            label="Description"
            id="description"
            value={formData?.description || ' ' }
            multiline
            maxRows={2}
            disabled={editingMode}
            onChange={(e) => handleForm(e)}
          />

        </Box>

        </FormGroup>        
      </CardContent>
      <CardActions>
        <Stack spacing={2} direction={'row'}>
          <ButtonGroup>
            <LoadingButton
              variant="outlined"              
              loading={loading}
              onClick={isNew ? handleSubmit : handleEdit}
              disabled={disabled || editingMode}
            >Submit
            </LoadingButton>              
          </ButtonGroup>
        </Stack>
      </CardActions>
    </Card>
  )
}

export default ScheduleForm