import React from "react";

import PageTitle from "components/ui/typography/PageTitle";
import TabsRouter from "components/ui/tabs/TabsRouter";


const MembersMain = () => {
  
  const links = [
    {
      "label": 'Users',
      "link": './'
    },
    {
      "label": "Invitations",
      "link": './invitations'

    }
  ]

  return (<>
    <PageTitle title={'Manage Members'} />    
    <TabsRouter tabs={links} />      
  </>)
}

export default MembersMain