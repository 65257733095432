import React from "react";

// M-UI
import { Card } from "@mui/material";

const MyCard = (props) => {  
  const {fullWidth} = props

  const cardWith = fullWidth ? '1200px' : '500px';
  const cardStyles = {
     padding: '1rem 0', borderRadius: '6px', margin: '1rem auto', width: '100%', maxWidth: cardWith
  }
  return (
    <Card style={cardStyles} sx={{
      '& .MuiTextField-root': { width: '100%' },
    }}   >
      {props.children}
    </Card>
  )
}

export default MyCard;