import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CardActions, CardMedia, CardContent, Alert, TextField, CardHeader, Stack } from '@mui/material'
import { LoadingButton } from "@mui/lab";

import AuthApi from "api/AuthApi";

const logo = require('assets/images/logo.png')

export function ResetPassword () {
  const { token } = useParams()
  const [formData, setFormData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState(false)
  const navigate = useNavigate()

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.id]:e.target.value
    })    
  }

  const submit = async () => {
    setLoading(true)
    await AuthApi.resetPassword(token, formData)
    .then(res => {

    })
    .catch(err => {
      setMsg({severity: 'error', msg:'Something went wrong'})
    })
    .finally(() => {
      setLoading(false)
      navigate('/')
    })
  }

  const disabled =  (
    (formData?.password === formData?.passwordConfirm) &&
    (formData?.password?.length >= 8 || formData?.passwordConfirm?.length >= 8)
  )


  return (
    
      <Stack sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth:'none',
        minHeight: '100vh',
        width: '100vw',
        background: "linear-gradient(rgba(0,0,0) 60%,rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url('/images/bg-image-two.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>

      <Card>
        <Stack direction={'row'}>
          <CardMedia component={'img'} src={logo} sx={{maxWidth: '50px', height: 'auto'}} alt='Team Manager'/>
          <CardHeader title={'Password Reset'} />
        </Stack>
        <CardContent>

        <Box
          component='form'         
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
            display: {xs: 'flex', md:'block'}, flexDirection:'column', alignItems:'center', justifyContent: {xs:'center', md:'center'}
          }}        
          onChange={(e) => {handleForm(e)}}        
        >
          <TextField 
            id='password'
            type="password"
            value={formData?.password || ''}
            label={'New password'}            
          />
          <TextField 
            id='passwordConfirm'
            type="password"
            value={formData?.passwordConfirm || ''}
            label={'Confirm new password'}
          />

          </Box>
          {msg && <Alert 
            severity={msg.severity}
            onClose={() => setMsg(false)}
            >
              {msg.msg}</Alert>}
          <CardActions>
            <LoadingButton
              variant="outlined"
              loading={loading}
              onClick={submit}
              disabled={!disabled}
            >
              Submit
            </LoadingButton>
          </CardActions>

        </CardContent>
      </Card>
      </Stack>
  )
}

