import React, { useContext, useState } from "react";
import { UserContext } from "context/UserContext";

import { Stack, ListItem, Divider } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import ScheduleListItem from "./ScheduleListItem";

const ScheduleList = () => { 
  const {events, userLoading} = useContext(UserContext);
  const [notificationState, setNotificationState] = useState(false)
  let prevDate= null;

  return (
    <>
      {!userLoading && events?.length == 0 && <>Nothing scheduled.</>} 
      <Stack direction={'row'} sx={{display: 'flex', flexWrap:'wrap', justifyContent: {xs: 'center', sm: 'start'}, pb:10}}>
        
        {!userLoading && events.length > 0 && events.map((event, index) => {   
          const isNewDate = event.eventFullDate !== prevDate
          prevDate = event.eventFullDate;
          
            return (
            <React.Fragment key={`${index}_scheduleItem`}>
              {isNewDate &&
              <Stack sx={{display: 'flex', width: '100%'}}>
                <ListItem sx={{width: '100%'}}>{event.eventFullDate}</ListItem>                                
                <Divider />
              </Stack>}
              <ScheduleListItem event={event} />              
            </React.Fragment>)          
        })}      
                    
      </Stack>      
               
        {userLoading && 
          <Stack direction={'column'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', height: '80vh' }}>
            <CircularProgress />
            <br/>Loading schedule....
          </Stack>}
        
    </>
  )
}

export default ScheduleList