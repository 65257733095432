import axios from "axios";


const api = axios.create({
  baseURL: `${window.location.origin}/api/v1/`,  
  withCredentials: true,
  headers: {
    "Content-Type" : "application/json"    
  }
})

// Add a request interceptor
api.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if (error.response.status === 401) {    
    return error.response
  } else {
    return Promise.reject(error);
  }
});

export default api;