import React from "react";
import { Box, Typography } from '@mui/material';

const PageTitle = (props) => {
  const { title } = props

  return (
    <Box sx={{mb:2 }}>
      <Typography variant="h5">{title}</Typography>
    </Box>      
  )
}

export default PageTitle