import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Page
import Profile from "./profile/Profile";
import Account from "./account/Account";
import { Settings } from "./settings/Settings";


const UserIndex = () => {  
  
  return(    
    <Routes>          
      <Route path="profile" element={ <Profile /> } />
      <Route path="account" element={ <Account /> } />          
      <Route path="settings" element={ <Settings /> } />          
    </Routes>    
  )
}

export default UserIndex