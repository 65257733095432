import React, { useState, useEffect, useContext } from "react";
import { Stack, ListItem, Divider, ButtonGroup } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';

import ScheduleListItem from "pages/view/schedule/ScheduleListItem";
import { UserContext } from "context/UserContext";

import EventApi from "api/EventApi";

export function TeamEvents (props) {
  const {team} = props
  const {events} = useContext(UserContext)
  const [teamEvents, setTeamEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(false);
  const [pastEvents, setPastEvents] = useState(false)

  let prevDate= null;


  const getTeamEvents = () => {
    setLoading(true)
    setTeamEvents(events.filter(event => event.homeTeam._id === team._id))    
    setLoading(false)
  }

  const getPastEvents = async () => {

  }

  useEffect(() => {
    getTeamEvents()
  }, [team, events])


  
  return (<>
        <Stack direction={'row'} 
          sx={{
            display: 'flex',
            flexWrap:'wrap',
            justifyContent: {xs: 'center', sm: 'start'}, 
            pb:5            
          }}
        >

        
          {/* <ToggleButton            
            value="check"
            sx={{my: 3}}
            selected={selected}
            onChange={() => {
              setSelected(!selected);
            }}
          >
            {selected ? 'Showing Past Events' : 'Showing Future Events'}
          </ToggleButton> */}
        
        
        {teamEvents.length > 0 && teamEvents.map((event, index) => {   
          const isNewDate = event.eventFullDate !== prevDate
          prevDate = event.eventFullDate;
          
            return (
            <React.Fragment key={`${index}_scheduleItem`}>
              {isNewDate &&
              <Stack sx={{display: 'flex', width: '100%'}}>
                <ListItem sx={{width: '100%'}}>{event.eventFullDate}</ListItem>                                
                <Divider />
              </Stack>}
              <ScheduleListItem event={event} />              
            </React.Fragment>)          
        })}      
        {(!loading && teamEvents.length == 0) && <>Nothing scheduled.</>}             
      </Stack>      
               
        {loading && 
          <Stack direction={'column'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', height: '100vh' }}>
            <CircularProgress />
            <br/>Loading schedule....
          </Stack>}

  
  </>)
}