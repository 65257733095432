import api from "./_api";

class OrgApi {
  create (data) {
    return api.post('organization', data)
  }
  get () {
    return api.get('organization');
  }  
  update(id, data) {    
    return api.put(`organization/${id}`, data)
  }
  delete(id) {
    return api.delete(`organization/${id}`)
  }
  // getPriv(id) { 5/8/2024
  //   return api.get(`organization/priv/${id}`)
  // }
}

export default new OrgApi();