import React from "react";

import PageTitle from "components/ui/typography/PageTitle";
import TabsRouter from "components/ui/tabs/TabsRouter";

const TeamMain = () => {
  const links = [
    {
      "label": 'Teams',
      "link": './'
    },
    {
      "label": 'Players',
      "link" : './players'
    },
  ]
  // {
  //   "label": 'Members',
  //   "link" : './members'
  // },
  // {
  //   "label": 'Invitations',
  //   "link" : './invitations'
  // }

  
    return (
      <>    
        <PageTitle title={'Manage Teams'}  />
        <TabsRouter tabs={links} />        
      </>      
    )
}

export default TeamMain