import React, { useContext, useState } from "react";

import { Card, CardHeader, CardMedia, Divider, ListItem, ListItemText } from "@mui/material";
import EventAttendance from "./EventAttendance";
import MyAccordion from "components/ui/accordion/MyAccordion";
import GMap from "components/ui/map/GMap";


const ScheduleListItem = (props) => {
  const { event } = props
  const currentDate = new Date().toISOString()

  const eventStyles = (event) => {        
      if (event === 'Game' || event === 'Scrimmage') return 'green'
      if (event === 'Practice') return '#1D4CD7'
      if (event === 'Fundraiser') return '#C1780B'
      if (event === 'Other') return '#45565E'
  }  

  const subHeader = 
      <ul>
        <ListItemText>{event?.eventFullDate} at {event?.eventTime}</ListItemText>
        <ListItemText>{ currentDate >= event?.date ? 'In Progress' : ''} </ListItemText>
      </ul>

  return (
    <Card 
      sx={{
        m: 1,        
        maxWidth: {xs:'300px', md:'365px'},
        width: '100%',
        height: 'fit-content',
        boxShadow: currentDate >= event.date ? '0px 0px 10px '+ eventStyles(event.eventName) : ''        
    }}>
    <CardHeader 
      title={`${event.eventName} - ${event.homeTeam.fullName} `}
      subheader={subHeader}
      titleTypographyProps={{variant:'h6' }}
      sx={{
        backgroundColor: eventStyles(event.eventName),
        color: '#fff',
        '&.MuiCardHeadersubheader': {
          color: '#fff'
        }
      }}   
    />         
     <Divider />
     {event?.venue?.address &&
        <MyAccordion title={event.venue.name}> 
          <GMap place={`${event.venue.name}${event.venue.address}`} />         
          <CardHeader subheader={event.venue.address} />
      </MyAccordion>}

     <MyAccordion title='Details'>
        {event?.awayTeam?.fullName && 
          <ListItem variant="p">
            {`vs ${event?.awayTeam?.fullName}`}
          </ListItem>}

        {event?.otherTeam && 
          <ListItem variant="p">
            {`vs ${event?.otherTeam}`}
          </ListItem>}

        {event?.notes && 
          <ListItem variant="body">
            {event.notes}
          </ListItem>}

        {event?.description && 
          <ListItem variant="body">
            {event.description}
          </ListItem>}
    </MyAccordion>

    <EventAttendance event={event} />

  </Card>
  )
}

export default ScheduleListItem