import React, {useState ,useContext, useEffect} from "react";
import validator from "validator";

import {Stack, Card, Box, CardMedia, CardHeader, CardActions, CardContent, TextField, Link, Alert } from "@mui/material";

// Context
import { AuthContext } from "context/AuthContext";
import { AppLoadContext } from "context/AppLoadContext";

// Loading button
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";

const logo = require('assets/images/logo.png')


const LoginPage = () => {
  const { login, loading, loginError, setLoginError} = useContext(AuthContext);
  const { setAppLoading } = useContext(AppLoadContext);
  const [formData, setFormData] = useState(null);  
  const navigate = useNavigate()

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })       
  }

  useEffect(() => {
    setAppLoading(false)
    document.body.classList.add('login')
    return () => {
      document.body.classList.remove('login')
    }
  },[]) 

  
  return (
    
      <Stack sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth:'none',
        minHeight: '100vh',
        width: '100%',
        background: "linear-gradient(rgba(0,0,0) 60%,rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url('/images/bg-image-two.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>      
      <Card>          
        <Stack direction={'row'}>
          <CardMedia component={'img'} src={logo} sx={{maxWidth: '50px', height: 'auto'}} alt='Team Manager'/>
          <CardHeader title={'Log in'} />
        </Stack>                    
        <CardContent  >
          <Box 
            component='form'         
            sx={{'& > :not(style)': { m: 1, width: '25ch' },
                display: {xs: 'flex', md:'block'}, flexDirection:'column', alignItems:'center', justifyContent: {xs:'center', md:'center'}
                }}                        
            onChange={ (e) => handleForm(e) }
          >
            
          <TextField    
            variant="outlined"
            id='email'
            label="Email"
            type='email'
            error={(formData?.email && !validator.isEmail(formData.email)) && 'not email'}
          />

          <TextField    
            variant="outlined"
            id='password'
            label="Password"
            type='password'
            error={formData?.password && validator.isStrongPassword(
                formData.password, [{minLength: 8}]
              )}                        
          />
          </Box>

            {loginError && 
              <Alert 
                severity="error"
                onClose={() => setLoginError(false)}
                > {loginError}</Alert>}

        </CardContent>

        <CardActions 
          sx={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>            
          <LoadingButton             
            onClick={() => login(formData)}
            loading={ loading || false } 
            variant="outlined"
            disabled={
              !(formData?.email && validator.isEmail(formData.email)) ||
              !formData?.password
            }

          >
            Login
          </LoadingButton>
          <Link
            underline="none"            
            component="button"
            variant="body2"
            onClick={() => {
              navigate('/forgotpassword')
            }}
            >
              Forgot Password
            </Link>
        </CardActions>

      </Card>        
    </Stack>    
  )
}

export default LoginPage