import React, { useContext, useEffect, useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import {
  GridRowModes,
  DataGrid,
  GridRowId,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbarQuickFilter,
  useGridApiRef
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

import MyAccordion from "components/ui/accordion/MyAccordion";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { rosterColumns } from "../TeamsConfig";
import { TeamContext } from "context/TeamContext";
import { PlayerContext } from "context/PlayerContext";

import TeamApi from "api/TeamApi";
import PlayerApi from "api/PlayerApi";


const Roster = (props) => {
  const { teamId } = props

  const { setTeamPlayers, teamPlayers, getTeamPlayers,
          removePlayerFromTeam, editRosteredPlayer,
          loading, setLoading } = useContext(TeamContext)

  const { setPlayers, getPlayers, setPlayersLoading } = useContext(PlayerContext)    
  const [columns, setColumns] = useState([]);    

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });    
  };

  const handleDeleteClick = (id) => async () => { 
    setLoading(true)    
    const player = {teamPlayer: id}    
    await TeamApi.removePlayer(teamId, player).then((res) => {
    }).finally(() => {
      setLoading(false)
      setPlayersLoading(true)   
    })
    await PlayerApi.get().then((res) => {
      if (res.status === 200) {
        setPlayers(res.data.doc)        
        getTeamPlayers(teamId)
      }
    }).finally(() => {          
      setPlayersLoading(false)
    })    
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => ((row.id || row._id) === (newRow.id || newRow._id) ? updatedRow : row)));
    editRosteredPlayer(updatedRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)    
  };


  useEffect(() => {    
    setRows([])
    setColumns(actions.concat(rosterColumns))
    getTeamPlayers(teamId)       
  }, [])  

  useEffect(() => {
    setColumns(actions.concat(rosterColumns))     
  }, [rowModesModel])


  const actions = [    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;    

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"              
              onClick={handleSaveClick(id)}
              color="success"
            />,                      
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="info"
            />,
          ];
        }

        return [                    
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit player'
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="info"
          />,
          <GridActionsCellItem 
            icon={<GroupRemoveIcon />}
            label='Removes Player from roster'
            title='Removes Player from roster'              
            onClick={handleDeleteClick(id)}
            color="error"     
          />,
          
        ];
      },
    },
  ];  




  return (
    <MyAccordion 
      title={'Current Roster'}
      expanded={false} 
      icon={<ArrowDownwardIcon />}      
    >      
    <DataGrid 
      autoHeight      
      rows={teamPlayers}
      columns={columns}
      getRowId={(row) => row.id || row._id}
      loading={loading}  
      editMode='row'   
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      slots={{
        loadingOverlay: LinearProgress,
      }}       

    />   
    </MyAccordion>
  )
}

export default Roster