import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';

import { Container } from '@mui/material';
import Toast from 'components/notifications/Toast';

import { AdminContext } from 'context/AdminContext';
import { UserProvider } from 'context/UserContext';
import { setupNotifications } from 'firebase';

import mainNav from 'components/ui/navigation/config/mainNav'
import ResponsiveAppBar from 'components/ui/navigation/ResponsiveAppBar';
import NativeNavigation from 'components/ui/navigation/NativeNavigation';

import HomePage from './HomePage';
import UserIndex from 'pages/user/UserIndex';
import ScheduleList from './schedule/ScheduleList';
import TeamList from './team/TeamList';
import { RoomsList } from './chat/RoomsList';
import { ChatList } from './chat/Chat';
import { Notifications } from './notifications/Notifications';

const ViewIndex = () => {  
const { native } = useContext(AdminContext)
 
  return (
    <>
      <ResponsiveAppBar pages={mainNav} isNative={native}/>
      <Container sx={{mt: 2, maxWidth:"xl"}}>
        
      <UserProvider>        
          <Routes>          
            <Route index element={<ScheduleList />} />           
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="teams" element={<TeamList />} />
            {/* <Route path="schedules" element={<ScheduleList />} /> */}
            <Route path='notifications' element={<Notifications />} />

            <Route path="rooms">  
              <Route index element={<RoomsList/>} />
              <Route path=":roomid" element={<ChatList />} />
            </Route>

            <Route path='user/*' element={<UserIndex />} />                   
          </Routes>          
      </UserProvider> 

      </Container>            
      
      { native && <NativeNavigation pages={mainNav}/> }
    </>
  )
}

export default ViewIndex