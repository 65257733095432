import React, { createContext, useContext, useEffect, useState } from "react";

import useDeviceInfo from "hooks/useDeviceInfo";
import { AdminContext } from "./AdminContext";

import UserApi from "api/UserApi";

export const UserDeviceContext = createContext(false)
export const UserDeviceProvider = ({children}) => {
  const { user } = useContext(AdminContext)  
  const [permission, setPermission] = useState(false)
  const currentDevice = useDeviceInfo()

  const registerNewDevice = async () => {      
    await UserApi.newDevice(user._id, currentDevice)
    .then(() => localStorage.removeItem('FCM Token'))   
    .catch(err => console.log(err))
  }

  const checkFCMPermission = async () => {
    const res = localStorage.getItem('pn')
    if (res === 'granted') setPermission(true) 
  }  
  
  useEffect(() => {
    checkFCMPermission()
  }, [])  

  useEffect(() => {        
    if (permission === 'true' && currentDevice?.token?.length) registerNewDevice()
  }, [permission] )

  const value={registerNewDevice}

  return (
    <UserDeviceContext.Provider value={value}>
      {children}
    </UserDeviceContext.Provider>
  )  
}