import React from "react";

import { Card, CardMedia } from '@mui/material';

export function YoutubeFrame (props) {
  const {songID} = props
    
  return (<>
    {songID &&
    <Card sx={{      
      p:0,
      backgroundColor: '#000',
      margin: '2rem auto',
      maxWidth: '285px'
    }} >
      <CardMedia
        component="iframe"
        sx={{maxWidth: '285px', height: 'auto' }}
        src={`https://www.youtube.com/embed/${songID}`}
        alt={'Sound Cloud'}
        />    
    </Card>}
  </>)  
}

