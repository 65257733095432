import React, { useContext, useEffect, useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,  
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { VenueContext } from "context/VenueContext";
import VenueForm from "./VenueForm";

import { venueColumns } from "./VenueConfig";


const VenueTable = () => {
  const [rowModesModel, setRowModesModel] = useState({});
  const [editingRow, setEditingRow] = useState(false)
  const [columns, setColumns] = useState([])

  const {venues, setVenues, loading, deleteVenue} = useContext(VenueContext)  
  const [showForm, setShowForm] = useState(false)  
  const [selected, setSelected] = useState(false)

  const openForm = () => {
    setShowForm(!showForm)
  }

  function EditToolbar(props) {
    const { openForm} = props;
  
    const handleClick = () => {
      openForm()
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Venue
        </Button>
      </GridToolbarContainer>
    );
  }  

  const handleEditClick = (id) => () => {   
    setEditingRow(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    setEditingRow(false)
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = venues.find((row) => venues.id === id);
    if (editedRow?.isNew) {
      setVenues(venues.filter((row) => venues.id !== id));
    }
  };

  const handleDeleteClick = (id) => async () => {    
    deleteVenue(id)
  };

  const onRowsSelectionHandler = (ids) => {    
    const selectedRowsData = ids.map((id) => venues.find((row) => row._id === id));        
    setSelected(selectedRowsData)        
  };

  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [            
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              title='Delete'              
              onClick={handleDeleteClick(id)}
              color="error"           
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="info"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="info"
            onClick={handleEditClick(id)}            
          />,
        ];
      },
    },

  ]

  React.useEffect(() => {
    setColumns(actions.concat(venueColumns))    
  }, [rowModesModel])


  useEffect(() => {
    setColumns(actions.concat(venueColumns))
  }, [])
  
  return (
    <>
     {!showForm && !selected &&
      <DataGrid 
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          }
        }}
        getRowId={(row) => row._id || row.id} 
        rows={venues}
        columns={columns}
        loading={loading}
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: {openForm}        
        }}    
      />}
      {showForm && !selected && <VenueForm  handleCancel={openForm} />}
      {!showForm && selected && <VenueForm  handleCancel={setSelected} data={selected} />}
    </>
  )
}

export default VenueTable