import React, { createContext, useState, useContext, useEffect } from "react";
import PlayerApi from "api/PlayerApi";

export const PlayerContext = createContext(false)

export const PlayerProvider = ({children}) => {  
  const [players, setPlayers] = useState([])
  const [playersLoading, setPlayersLoading] = useState(false)

  const newPlayer = async (data) => {
    await PlayerApi.new(data)
    .then((res) => {
      if (res.status === 200) {
        getPlayers()
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      return true
    })
  }

  const updatePlayer = async (id, data) => {
    setPlayersLoading(true)
    await PlayerApi.update(id, data)
    .then((res) => {
      console.log(res)
      getPlayers()
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      //setPlayersLoading(false)
    })
  }

  const getPlayers = async () => {
    setPlayersLoading(true)
    await PlayerApi.get().then((res) => {
      if (res.status === 200) {
        setPlayers(res.data.doc)
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setPlayersLoading(false)
    })
  } 


  useEffect(() => {
    getPlayers()
  }, [])

  let doc;
  const value = {players, setPlayers, getPlayers, newPlayer, setPlayersLoading, playersLoading, updatePlayer}

  return (
    <PlayerContext.Provider value={value}>
      {children}
    </PlayerContext.Provider>
  )
}