import React, { useState, useEffect } from "react";

import { Divider, ListItem } from "@mui/material";

export function ChatDate (props) {
    const { date } = props;

    
    return (
        <React.Fragment>            
            <ListItem sx={{justifyContent: 'center', my:2}}>{date}</ListItem>
        </React.Fragment>
    )    
    
}