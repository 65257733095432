import React, {useState, createContext, useEffect, useContext} from "react";
import { AdminContext } from "./AdminContext";

import OrgApi from "api/OrgApi";

export const OrganizationContext = createContext(false);

export const OrganizationProvider = ({ children }) => {
  const { userRole } = useContext(AdminContext)  
  const [ organization, setOrganization ] = useState([])
  const [ loading, setLoading ] = useState(false) 
  
  const getAllOrganizations = async () => {  
    setLoading(true)      
    await OrgApi.get().then((res) => {      
      if (res.status === 200) {
        setOrganization(res.data.doc)
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {      
      setLoading(false)
    })   
  }  

  const newOrganization = async (data) => {
    setLoading(true)
    await OrgApi.create(data)
    .then(() => {
      getAllOrganizations()      
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const editOrganization = async (id, data) => {
    setLoading(true)
    await OrgApi.update(id, data)
    .then((res) => {
      if (res.status === 200) { getAllOrganizations() }      
    }).finally(() => {
      setLoading(false)
    })
  }
  
  
  useEffect(() => {                
    getAllOrganizations()     
  }, [userRole])

  const value={
    loading,
    organization,
    setOrganization, 
    newOrganization,  
    editOrganization
  }

  return <OrganizationContext.Provider value={value}>
          {children}
        </OrganizationContext.Provider>  
}

