import api from "./_api";

class UploadApi {
  avatar(id, data, headers ={}) {    
    return api.put(`/upload/avatar/${id}`, data, {      
      headers: {        
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default new UploadApi()