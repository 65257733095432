import { useEffect, useState } from "react";

const useDeviceInfo = () => {
  const [ browserInfo, setBrowserInfo ] = useState(null)

  const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('mobi')) {
      return 'mobile';
    } else if (userAgent.includes('tablet')) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  useEffect(() => {
    const token =  localStorage.getItem('FCM Token')
    setBrowserInfo({
      platform: navigator.userAgentData?.platform,
      mobile: navigator.userAgentData?.mobile ? true : false,
      deviceType: getDeviceType(),      
      token: token
    })             
  }, []);  
  

  return browserInfo;
}

export default useDeviceInfo

