import React from "react";

import PageTitle from "components/ui/typography/PageTitle";
import TabsRouter from "components/ui/tabs/TabsRouter";


const ScheduleMain = () => {
  const links = [
    {
      "label": 'Events',
      "link": './'
    },
    {
      "label": "Venues",
      "link": './venues'
    }
  ]
  return (
    <>
      <PageTitle title={'Manage Schedules'} />
      <TabsRouter tabs={links} />
    </>
  )
}

export default ScheduleMain