import React from "react";
import {Modal, Box, Typography} from '@mui/material';

const MyModal = (props) => {
  const {title, body} = props;
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 300,
    width: '100%',
    bgcolor: 'background.paper',    
    boxShadow: 24,
    p: 3,
    m: 2,
    mx: 'auto'
  };


  return (
    <Modal      
      open          
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {body}
        </Typography>
      </Box>
    </Modal>
  )
}

export default MyModal