import React, { useContext, useEffect, useState } from "react";

import LinearProgress from '@mui/material/LinearProgress';
import {    
  DataGrid,  
  GridActionsCellItem,  
  GridToolbarQuickFilter  
} from '@mui/x-data-grid';

import { PlayerContext } from "context/PlayerContext";
import { TeamContext } from "context/TeamContext";
import TeamApi from "api/TeamApi";

import MyAccordion from "components/ui/accordion/MyAccordion";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Box } from "@mui/material";

import { playerColumns } from "../../players/form/PlayerConfig";
import PlayerApi from "api/PlayerApi";

const EditRoster = (props) => {
  const { teamId } = props
  const [ isLoading, setIsLoading ] = useState(false)
  const { players, setPlayers, playersLoading } = useContext(PlayerContext)  
  const { getTeamPlayers} = useContext(TeamContext)
  const [ columns, setColumns ] = useState([])
  const [rowModesModel, setRowModesModel] = React.useState({});  
  const [rows, setRows] = useState([])

  const inTeam = (arr, teamId ) => arr.some(obj => obj.id === teamId)

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  const addPlayer = (id) => async () => {
    const selectedPlayer = players.find((row) => row._id === id);    
    setIsLoading(true)
    const player = {player: id}    
    await TeamApi.addPlayer(teamId, player)
    getTeamPlayers(teamId)
    await PlayerApi.get()
    .then((res) => {
      if (res.status === 200) {
        setPlayers(res.data.doc)        
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  } 

  const processRowUpdate = (newRow) => {    
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => ((row.id || row._id) === (newRow.id || newRow._id) ? updatedRow : row)));
    //editRosteredPlayer(updatedRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)    
  };
  

  useEffect(() => {
    setColumns(actions.concat(playerColumns))    
  }, [])

  const actions = [    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {        
        const hasTeam = inTeam(row.team, teamId);          

        return [
          <GridActionsCellItem 
            icon={<GroupAddIcon />}
            label='Add Player to roster'
            title='Add Player to roster'
            color='success' 
            onClick={addPlayer(id)}   
            sx={{display: hasTeam ? 'none' : 'block'}}      
          />,          
        ];
      },               
    },
  ];    

  return (    
    <MyAccordion
      title={'All Players'} 
      icon={<ArrowDownwardIcon />}      
      >
      <DataGrid 
        autoHeight
        rows={players}        
        columns={columns}
        getRowId={(row) => row.id || row._id}
        loading={isLoading || playersLoading}   
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}        
        processRowUpdate={processRowUpdate}       
        slots={{
          loadingOverlay: LinearProgress,          
          toolbar: QuickSearchToolbar
        }}       
           
      />
    </MyAccordion>  
 
  )
}

export default EditRoster