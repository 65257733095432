const returnDate = (value) => {
  const date = new Date(value);
  const day = date.getDate().toString(); 
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString();
  return `${month.toString()}/${day}/${year}` 
}

export const invitationColumns = [  
  {field: 'email', headerName: 'Email', width: 125 },
  {field: 'role', headerName: 'Role', width: 75,
  valueGetter: (value, row) => {             
    return value?.toString().charAt(0).toUpperCase() + value?.toString().slice(1)
  }
  },
  {field: 'organization', headerName: 'Org.', width: '150',
    valueGetter: (value, row) => {             
      return `${value?.name || ''} `
    }
  },
  {field: 'accepted', headerName: 'Accepted', width:  100,
    valueGetter: (value, row) => {       
      return `${value ? 'Yes' : 'No'}`
    }
  },        
  {field: 'team', headerName: 'To team', width: 150, 
    valueGetter: (value, row) => {
      return `${value?.fullName || 'Organization'}`
    }
  },
  {field: 'invitedBy', headerName: 'Invited by', width: 225, 
    valueGetter: (value, row) => {           
      return `${value?.firstName || ''} ${value?.lastName || ''} `
    }
  },
  {field: 'inviteDate', headerName: 'Inv. Date', width: 100,
    valueGetter: (value, row) => {       
      return returnDate(value)
    }
  },
  {field: 'inviteTokenExpire', headerName: 'Inv. Exp.', width: 150,
    valueGetter: (value, row) => { 
      return returnDate(value)
    } 
  },
]


export const newInviteFields = [
  {
    label: 'Email',
    id: 'email',
    type: 'email',
    default: 'user@email.com',
    validate: 'Invalid Email'
  }, 
  {
    label: 'Role',
    id: 'role',
    type: 'select' ,   
    default: 'Select a user role',       
    options: [      
      {
        label: 'Director',
        value: 'director',
        id: 'role',
        access: ['dev']
      },
      {
        label: 'Staff',
        value: 'staff',
        id: 'role',
        access: ['dev', 'director']
      },
      {
        label: 'Team manager',
        value: 'team-manager',
        id: 'role',
        access: ['dev', 'director', 'staff']
      },
      {
        label: 'Assistant team manager',
        value: 'team-assistant',
        id: 'role',
        access: ['dev', 'director', 'staff', 'team-manager']
      },
      {
        label: 'Parent or legal guardian',
        value: 'user',
        id: 'role',
        access: ['dev','director', 'staff' ,'team-manager', 'team-assistant']
      }
    ]
  }

]


