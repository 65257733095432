import React from "react";

import { TextField } from "@mui/material";
import BasicSingleSelect from "components/ui/form/components/BasicSingleSelect";


const ScheduleGames = (props) => {  
  const { handleForm, team, data, readOnly }  = props;

  const awayList = {
    label: 'Away Team',
    _id: 'awayTeam',
    options: team
  }  

  
  return (<>
    {awayList &&       
      <BasicSingleSelect 
      field={awayList}            
      data={data?.awayTeam?.id || ''}
      state={['']}
      handleForm={handleForm}  
      disabled={readOnly}
    />}
    
    {!data?.awayTeam && <TextField 
      sx={{ m: 1, maxWidth: 500, width: '100%' }}
      variant="outlined"
      type={'text'}
      label="Outside Team"
      value={data?.otherTeam || ''}
      id="otherTeam"
      disabled={readOnly}
      onChange={(e) => handleForm(e)}
    />}
  </>)

}

export default ScheduleGames