import React, { createContext, useState, useEffect} from "react";

import EventApi from "api/EventApi";
import TeamApi from "api/TeamApi";

export const EventContext = createContext(false)

export const EventProvider = ({ children }) => {
  const [ events, setEvents ] = useState([])
  const [ eventTeams, setEventTeams] = useState([])
  const [ loading, setLoading ] = useState(false) 

  const getEvents = async () => {
    setLoading(true)
    await EventApi.get().then((res) => {
      if (res.status === 200) {
        setEvents(res.data.doc)
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const newEvent = async (data) => {
    setLoading(true)
    await EventApi.create(data).then((res) => {
      getEvents()
    }).catch((err) => {
      console.log(err)
    })
  }

  const editEvent = async (data) => {
    setLoading(true)
    await EventApi.update(data._id, data)
    .then((res) => {
      if (res.status === 200) {
        getEvents()
      }
    })
  }

  const deleteEvent = async (id) => {
    setLoading(true)
    await EventApi.delete(id).then((res) => {
      if (res.status === 204) getEvents()
    })
  }

  const allTeamsForSchedule = async () => {
    setLoading(true)
    await TeamApi.allTeamsForSchedule()
    .then((res) => {
      if (res.status === 200) {
        setEventTeams(res.data.doc)
      }    
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  

  useEffect(() => {
    getEvents();    
  }, [])

  const value={
    events, setEvents,
    loading, newEvent,
    deleteEvent, editEvent,
    allTeamsForSchedule, eventTeams
  }

  return (
    <EventContext.Provider value={value}>
      {children}
    </EventContext.Provider>
  )
}