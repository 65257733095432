import React, {useEffect, useState} from "react";

import validator from "validator";

import { IconButton, Box } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import UploadIcon from '@mui/icons-material/Upload';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import dayjs from "dayjs";

import { YoutubeFrame } from "./YoutubeFrame";

export function YoutubeFrameGetter (props) {
  const {data, setWalkUp } = props
  const [verifiedSong, setVerifiedSong] = useState(false)
  const [formData, setFormData] = useState(data)  
  const [start, setStart] = useState()

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value      
    })    
  }

  const handleYoutubeURL = () => {  
    let url = validator.isURL(formData?.walkup)
    if (url) {      
      const regex = /https:\/\/youtu\.be\/(.+)/;
      let songID = formData.walkup.match(regex)  
      songID = songID[1] + `&amp;start=${start}`
      setWalkUp(songID)
      setVerifiedSong(true)      
    }
  }

  const handleStart = (e) => {
    const min = dayjs(e).minute();
    const sec = dayjs(e).second();
    const time = min * 60 + sec; 
    time ? setStart(time) : setStart('00')
  }

  useEffect(() => {
    if (!formData?.walkup || formData?.walkup !== data ) {
      setVerifiedSong(false)
    }
  }, [formData?.walkup])
  return (<>
      {data && <YoutubeFrame songID={data} />}

      <Box 
      component='form'             
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
        display:'flex', flexWrap:'wrap'
      }}
    >       

      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>  
        <InputLabel htmlFor="walkUpStart">Song start min/sec</InputLabel>          
            <DesktopTimePicker
              views={['minutes', 'seconds']}
              timeSteps={{ minutes: 1, seconds: 1 }}
              format="mm:ss" 
              id={'walkUpstart'}
              onChange={(e) => {handleStart(e)}}
            />
        </LocalizationProvider>
      </Box>

      <Box>
        <InputLabel htmlFor="walkup">Youtube share url</InputLabel>
        <OutlinedInput
          id="walkup"                            
          value={formData?.walkup || '' }                                    
          onChange={(e) => handleFormData(e)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton                  
                onClick={handleYoutubeURL}                  
                edge="end"
                title='Load song'
                >
                <UploadIcon color={verifiedSong ? 'success' : 'error'}/>
              </IconButton>
            </InputAdornment>
          }
          />  
      </Box>
      
      </Box>  
  </>)
}

