import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Button } from '@mui/material';

const Toast = (props) => {  
  const type = props.type
  const msg = props.msg  

  const conf = {
    position: "top-right",
    theme: localStorage.getItem('theme') || 'light',
    CloseButton: null
  }

  const Data = () => {    
    return (
      <div className="msg-container">
        <p className="msg-title">{msg.title}</p>
        <p className="msg-description">{msg.text}</p>
        {msg.onClick &&
          <Button 
            variant='outlined'
            onClick={msg.onClick}
          >{msg.btnText} </Button > }          
      </div>
    )
  }

  if (type === 'success') { return toast.success(<Data />, conf) }  
  if (type === 'error') { return toast.error(<Data />, conf); }
  if (type === 'warn') { return toast.warn(<Data />, conf);}
  if (type === 'info') { return toast.info(<Data />, conf);} 

  return  toast(<Data />, conf);
}

export default Toast