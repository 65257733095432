import React, { useState } from "react";

import { Box, Tabs, Tab } from "@mui/material";
import PropTypes from 'prop-types';

import { TeamStaff } from "./TeamStaff";
import { TeamEvents } from "./TeamEvents";
import { TeamMembers } from "./TeamMembers";
import { RoomsList } from "pages/view/chat/RoomsList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, my:3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function TeamTabs(props) {
  const {team} = props
  const [value, setValue] = useState(0);
  
  const displayTabs = [
    {
      label: 'Schedule',
      component: <TeamEvents team={team} />
    },
    {
      label: 'Roster',
      component: <TeamStaff team={team} />
    },
    {
      label: 'Team Members',
      component: <TeamMembers team={team} />
    },
    {
      label: 'Team Chat',
      component: <RoomsList team={team} />
    }
  ]  
 
  const handleChange = (event, newValue) => {    
    setValue(newValue);
  };  

  return (
    <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Team Details"
            variant="scrollable"
            scrollButtons="auto"
          >                    

            {displayTabs.map((detail, i) => {              
              return (
                <Tab key={i} label={detail.label} {...a11yProps(i)} />
              )
            })}

          </Tabs>
        </Box>

        {displayTabs.map((detail, i) => {              
          return (                
            <CustomTabPanel key={i} value={value} index={i}>          
              {detail.component}
            </CustomTabPanel>
          )
        })}     

      </Box>
  )
}