import React, { useContext, useEffect, useState } from "react";

import { Card, Stack, Avatar, CardHeader, IconButton, Box, TextField, Alert, CardContent, CardActions } from '@mui/material'
import { LoadingButton } from "@mui/lab";

import { AdminContext } from "context/AdminContext";

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import MyAccordion from "components/ui/accordion/MyAccordion";

import UserApi from "api/UserApi";



export function UserInformation (props) {
  const { user, setUser } = useContext(AdminContext)  
  const [editUser, setEditUser] = useState(false)  
  const [formData, setFormData] = useState()
  const [buttonLoad, setButtonLoad] = useState(false)
  const [error, setError] = useState(false)

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      email: user.contact.email.value || user.contact.email,
      [e.target.id] : e.target.value
    })
  }

  const submit = async () => {    
    setButtonLoad(true)    
    await UserApi.updateUser(user._id, formData)
    .then((res) => {            
      if (res.status === 200) {
        setUser(res.data.data)
      }
      if (res.status === 401) {
        setError(res.data.msg)
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setButtonLoad(false)
      setEditUser(false)
    })
    
  }

  useEffect(() => {
    setFormData(user)
  }, [user])

  return (
    
        
        <MyAccordion title={'Personal Information'} > 

        <IconButton 
          onClick={() => {setFormData(user); setEditUser(!editUser)} }          
        >
          { editUser ? <EditOffIcon /> : <EditIcon /> }
        </IconButton>
    
      {error && <Alert severity="error" onClose={(() => setError(false))}>{error}</Alert>}
      
        <CardContent>    
          <Box 
            component='form'         
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' }              
            }}
            >
            <TextField               
              label={'First Name'}
              id={'firstName'}
              value={formData?.firstName || ''}
              onChange={(e) => handleFormData(e)}
              disabled={!editUser}
              variant={editUser ? 'outlined' : 'standard'}

              />

            <TextField 
              label={'Last Name'}
              id={'lastName'}
              value={formData?.lastName || ''}
              onChange={(e) => handleFormData(e)}
              disabled={!editUser}
              variant={editUser ? 'outlined' : 'standard'}
              />
        
            {editUser &&
            <TextField 
              variant="outlined"
              label={'Confirm Password'}
              id={'password'}        
              type={'password'}              
              onChange={(e) => handleFormData(e)}
              />}
          
          </Box>
          {(!formData?.password && editUser) &&<Alert severity="info">Enter Password to submit</Alert>}
        </CardContent>

        {editUser && <CardActions>
          <LoadingButton
            variant="outlined"
            loading={buttonLoad}
            disabled={!formData?.password} 
            onClick={submit}       
            >Submit </LoadingButton>
        </CardActions>}
        
        
    </MyAccordion>
  )
}


