import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";


const FormTextField = (props) => {
  const { field, handleForm, required, data, readOnly } = props;
  const [ inputValue, setInputValue ] = useState('')  

  const handleField = (e) => {        
    handleForm(e)
    setInputValue(e.target.value)
  } 
  
  useEffect(() => {
    if (data && data.length)
    setInputValue(data)
  },[data])

  

  return (<>
    {field && <TextField      
      //key={`${field._id}_key`}         
      sx={{ m: 1, maxWidth: 500, width: '100%' }} 
      id={field._id}       
      name={field._id}   
      type={field._id}                  
      label={field.label}                 
      onChange={(e) => handleField(e)}          
      value={inputValue}                   
      required={required}   
      readOnly={true}
      autoFocus   
      disabled={readOnly}
      //error={error[field._id]}
   />}</>
  )
}

export default FormTextField