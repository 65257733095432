import React, {useEffect, useContext, useState} from "react";


import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarQuickFilter  
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import { Box, Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { TeamContext } from "context/TeamContext";
import NewTeamForm from "./NewTeamForm";

// Table data
import { teamColumns } from "./TeamsConfig";

const TeamTable = () => {
  const { team, setTeam, loading } = useContext(TeamContext)
  const [openForm, setOpenForm] = useState(false)
  const [selected, setSelected] = useState()
  
  // Table
  const [columns, setColumns] = useState([]);      
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = React.useState({});  
  
  const toolbarAction = () => {
    setOpenForm(!openForm)
  }  
  
  const handleSelection = (item) => {    
    setSelected(item)
  }
  
  // Table ////////
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }
  function EditToolbar(props) {
    const { toolbarAction } = props;
  
    const handleClick = () => {
      toolbarAction()
    };
  
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}    
        sx={{
          p: 0.5,
          pb: 0,
          justifyContent:'space-between'
        }}
      >
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          create team
        </Button>
      </GridToolbarContainer>
      <GridToolbarQuickFilter />
    </Stack>
    );
  }  

  const handleEditClick = (id) => () => {   
    //setEditingRow(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    //setEditingRow(false)
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    // const editedRow = venues.find((row) => venues.id === id);
    // if (editedRow?.isNew) {
    //   setVenues(venues.filter((row) => venues.id !== id));
    // }
  };

  const handleDeleteClick = (id) => async () => {    
    //deleteVenue(id)
  };

  const onRowsSelectionHandler = (ids) => {    
    const selectedRowsData = ids.map((id) => team.find((row) => row._id === id));            
    setSelected(selectedRowsData)     
  };


  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const cancelDelete = row?.roster?.length > 0;        

        if (isInEditMode) {
          return [            
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              title='Delete'              
              onClick={handleDeleteClick(id)}
              disabled={cancelDelete}
              color='error'           
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="info"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="info"
            onClick={handleEditClick(id)}            
          />,
        ];
      },
    },

  ]

  // End Table //////////

  useEffect(() => {
    setColumns(actions.concat(teamColumns))
  }, [])

  useEffect(() => {
    setColumns(actions.concat(teamColumns))
  }, [rowModesModel])

  return(<>
  {!openForm && !selected && 
    <DataGrid 
      autoHeight
      rows={team}
      columns={columns}
      getRowId={(row) => row.id || row._id}
      editMode='row'   
      rowModesModel={rowModesModel}  
      loading={loading}   
      onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)} 
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: EditToolbar
      }}         
      slotProps={{
        toolbar: {toolbarAction}        
      }}     
      />
    }  

    {!openForm && selected && 
      <NewTeamForm handleCancel={handleSelection} data={selected} />}
    {openForm && !selected && 
      <NewTeamForm handleCancel={toolbarAction} />}
  </>)  
}

export default TeamTable