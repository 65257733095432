import React from "react";

import { Button, Divider, Alert, Stack} from "@mui/material";

import { Clear } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';

const PlayerList = (props) => {
  const {players, setPlayers} = props  

  const deleteItem = (targetIndex) => {
    let newPlayers = players.filter((item, index) => index !== targetIndex);
    setPlayers(newPlayers)
  }
if (players?.length > 0) {

  return (<>  
    {players && <>
      {players.map((player, index) => {
        return (<Stack key={index}>
          <Divider sx={{m:2}}/>
          <Alert icon={player.confirmed ?  <CheckIcon fontSize="inherit" /> : false} sx={{alignItems: 'center', flexWrap: 'wrap'}} severity={player.confirmed ? 'success' : 'info' }>

            {`${player.firstName} 
              ${player.lastName}
              ${player.dob}
              `}

              {!player.confirmed &&
                <Button onClick={() => deleteItem(index) }><Clear /></Button>}
            
          </Alert>
        </Stack>)
      })}
    </>}
  </>)
    }
}

export default PlayerList