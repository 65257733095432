import React, {useState, createContext, useEffect, useContext} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthApi from "api/AuthApi";
import { AppLoadContext } from "./AppLoadContext";

export const AdminContext = createContext(false)

export const AdminProvider = ({ children }) => {
  const { setAppLoading } = useContext(AppLoadContext);
  const [ searchParams ] = useSearchParams(false);
  const [ response, setResponse ] = useState(false);
  const [ profile, setProfile ] = useState(false);
  const [ user, setUser ] = useState(false);  
  const [ currentOrg, setCurrentOrg ] = useState([]);
  const [ userRole, setUserRole ] = useState(false);  
  const [ native, setNative ] = useState(false)

  const getAccessToken = async (id) => {
    setAppLoading(true)
    const token = await AuthApi.getAccessToken(id)      
    token && setAppLoading(false)    
  }

  const setCurrentOrganization = (selectedProfile) => {    
    const id = selectedProfile.user.id;
    setUser(selectedProfile.user);
    setUserRole(selectedProfile.role);
    setCurrentOrg(selectedProfile.organization);
    getAccessToken(selectedProfile.id);    
    setAppLoading(false);
  }

  useEffect(() => {
    setNative(searchParams.get('appcontainer'))
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
         setNative(true)
      } else {
         setNative(false)
      }
  },[])

  useEffect(() => {         
    let availProfiles = []
    response && response?.forEach((option) => {          
      option?.forEach((obj) => {        
        availProfiles.push(obj)        
      })
    })            
    setProfile(availProfiles)    
  }, [response])


  const value = {    
    setCurrentOrganization,
    response, setResponse,
    profile, setProfile,
    user, setUser,
    currentOrg, setCurrentOrg,
    userRole, setUserRole,
    native
  }

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}