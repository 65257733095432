import api from "./_api";

class PlayerApi {   
  get() {
    return api.get('/player')
  }   

  new (data) {
    return api.post('/player', data)
  }

  update (id, data) {    
    return api.put(`/player/${id}`, data)
  }
}

export default new PlayerApi()