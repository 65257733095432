export const organizationColumns = [    
  {field: 'name', headerName: 'Name', width: 300},
  {field: 'shortName', headerName: 'Short Name', width: 100},  
  {field: 'contact.tel', headerName: 'Tel', width: 100,
    valueGetter: (value, row) => {
      return row?.contact?.tel?.value
    }
  },
  {field: 'contact', headerName: 'Email', width: 200,
    valueGetter: (value, row) => {
      return row?.contact?.email?.value
    }
  },
  {field: 'contact.address', headerName: 'Address', width: 450,
    valueGetter: (value, row) => {      
      return row?.contact?.fullAddress
    }
  }
];

export const organizationForm = [
  {    
    id: 'name',
    label: 'Organization Name',
    type: 'text',
    required: true    
  },
  {    
    id: 'shortName',
    label: 'Short Name',
    type: 'text',
    required: true    
  }
]

// {    
  //   id: 'logo',
  //   label: 'Organization Logo',
  //   type: 'file'
  // }

  export const organizationContactForm = [
    {    
      id: 'address',
      label: 'Address',
      type: 'text'
    },
    {    
      id: 'city',
      label: 'City',
      type: 'text'
    },
    {    
      id: 'zipCode',
      label: 'Zip Code',
      type: 'number'
    },
    {    
      id: 'state',
      label: 'State',
      type: 'text'
    }, 
    {    
      id: 'tel',
      label: 'Phone Number',
      type: 'number'
    },
    {    
      id: 'email',
      label: 'Email Address',
      type: 'email',
    }
  ];
  
