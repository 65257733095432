import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import dayjs from 'dayjs';


export default function MyDatePicker(props) {
  const {field, format, handleDate, data, readOnly, view} = props

  const dateGetter = (e) => {    
    const date = dayjs(e).format(format)
    handleDate(date)
  }

  React.useEffect(() => {
    dateGetter(data)
  }, [data])

  return (
    <FormControl sx={{ my: 1, maxWidth: 500, width: '100%' }}>
    <LocalizationProvider dateAdapter={AdapterDayjs} >      
      <DatePicker 
        views={view || ['year', 'month', 'day']}      
        label={field.label}
        id={field._id}
        onChange={(e) => dateGetter (e)}  
        value={dayjs(data || '')}    
        disabled={readOnly}              
      />
    </LocalizationProvider>
    </FormControl>
  );
}