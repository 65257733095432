import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';

export const userMenu = [
  {
    id: 1,
    text: 'Profile',
    href: '../user/profile',   
    icon: <AccountCircleIcon />  
  },
  {
    id: 2,
    text: 'Account',
    href: '../user/account',
    icon: <ManageAccountsIcon />
  },
  {
    id: 3,
    text: 'Settings',
    href: '../user/settings',
    icon: <SettingsIcon />
  },
  // {
  //   id: 4,
  //   text: 'Notifications',
  //   href: '../notifications',
  //   icon: <NotificationsIcon />
  // }  
];

