import React from "react";
import { CardMedia } from '@mui/material'
import { Streetview } from "@mui/icons-material";

const GMap = (props) => {
  const {place} = props 
  
  return (<>
    {place && 
      <CardMedia
      component={'iframe'}      
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_G_KEY}&q=${place}`} 
    />}
  </>)

}

export default GMap