import React from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { GridArrowDownwardIcon } from "@mui/x-data-grid";

const MyAccordion = (props) => {
  const {title, icon, expanded} = props;

  return (
    <Accordion
      defaultExpanded={expanded} 
    >

      <AccordionSummary      
        expandIcon={icon || <GridArrowDownwardIcon />}            
      >
        {title}
      </AccordionSummary>

      <AccordionDetails>{props.children}</AccordionDetails>

    </Accordion>
  )

}

export default MyAccordion