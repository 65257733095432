import React, { useContext } from 'react';
import { AppLoadContext } from 'context/AppLoadContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



const Loader = (props) => {  
  const {appLoading} = useContext(AppLoadContext)
  return (
    <Backdrop
      sx={{ color: '#fff', background: 'rgba(0,0,0,0.9)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={appLoading}        
    >
      <CircularProgress color="inherit" />      
    </Backdrop>
  )
}

export default Loader