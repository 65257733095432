import React, {useState, useEffect, useContext} from "react";

import { Button, Box, Fade, Alert } from '@mui/material'

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,  
  GridActionsCellItem  
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Redo from '@mui/icons-material/Redo';

import { InvitationContext } from "context/InvitationContext";
import { invitationColumns } from "./InvitationTableConfig";

const InvitationTable = (props) => {
  const { invitations, setInvitations, deleteInvitation,
     loading, handleResendClick, setInvitationMsg, invitationMsg } = useContext(InvitationContext) 
  const { toolbarAction } = props
  // Table
  const [rowModesModel, setRowModesModel] = useState({});
  const [editingRow, setEditingRow] = useState(false)
  const [columns, setColumns] = useState([])

  function EditToolbar(props) {
    const {toolbarAction} = props
    
  
    const handleClick = () => {
      toolbarAction()
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          New invitation
        </Button>
      </GridToolbarContainer>
    );
  }  


  const handleEditClick = (id) => () => {   
    setEditingRow(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    setEditingRow(false)
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = invitations.find((row) => invitations.id === id);
    if (editedRow?.isNew) {
      //setVenues(venues.filter((row) => venues.id !== id));
    }
  };

  const handleDeleteClick = (id) => async () => {    
    deleteInvitation(id)  
    return setInvitations(invitations.filter((row) => row.id !== id));    
    
  };  

  const disableResendButton = (row) => {
    if (row && !row.accepted) return false
    if (row && row.accepted) return true
    return true
 }


  React.useEffect(() => {
    setColumns(actions.concat(invitationColumns))    
  }, [rowModesModel])


  useEffect(() => {
    setColumns(actions.concat(invitationColumns))
  }, [])

  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, value, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const resend = disableResendButton(row)

        if (isInEditMode) {
          return [                        
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              title='Delete'              
              onClick={handleDeleteClick(id)}
              color='error'         
              />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              color="info"
              onClick={handleCancelClick(id)}
            />,
          ];
        }

        return [
          <GridActionsCellItem            
              icon={<Redo />}
              label="Resend"
              title='Send'
              color="success"               
              onClick={handleResendClick(id)}
              disabled={resend}                             
            />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title='Edit'
            className="textPrimary"
            color="info"
            onClick={handleEditClick(id)}            
          />,
        ];
      },
    },

  ]

  return (<>    
    {invitationMsg &&
      <Fade in={invitationMsg ? true : false}>
        <Alert 
          severity={invitationMsg.severity}
          onClose={() => setInvitationMsg(false)}
        >{invitationMsg.msg}</Alert>
      </Fade>
    }
    <DataGrid 
      autoHeight
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'desc' }],
        }
      }}
      getRowId={(row) => row._id || row.id} 
      rows={invitations}
      columns={columns}
      loading={loading}
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: EditToolbar
      }}
      slotProps={{
        toolbar: {toolbarAction}        
      }}    
    />  
  </>)
}

export default InvitationTable