
export const eventColumns = [
  {field: 'eventName', headerName: 'Event', width: 100},
  {field: 'eventFullDate', headerName: 'Event Date', width: 200},
  {field: 'homeTeam', headerName: 'Home Team', width: 100, 
    valueGetter: (value, row) => {      
      return `${value?.name}`
    }
  },
  {field: 'homeTeam.division', headerName: 'Division', width: 75, 
    valueGetter: (value, row) => {      
      return `${row?.homeTeam?.division}`
    }
  },
  {field: 'homeTeam.year', headerName: 'Team Year', width: 100, 
    valueGetter: (value, row) => {      
      return `${row?.homeTeam?.year}`
    }
  },

  {field: 'eventTime', headerName: 'Time', width: 100},
  {field: 'venue', headerName: 'Venue', width: 250,
    valueGetter: (value, row) => {    
      return value?.name
    }
    },  
  {field: 'description', headerName: 'Description', width: 200},
  {field: 'notes', headerName: 'Notes', width: 150,},
  {field: 'awayTeam', headerName: 'Away Team', width: 100,
    valueGetter: (value, row) => {
      let data = value?.division ? `${value?.division} ${value?.name}` : ''
      return data
    }
  },
  {field: 'otherTeam', headerName: 'Team (other)', width: 100,},
  {field: 'score.home', headerName: 'Score Home'},
  {field: 'score.away', headerName: 'Score Away'}
]


export const eventType = 
  { 
    label: 'Event Type',
    _id: 'type',    
    options: [
      {
        label: 'Game',
        value: 'game',
        _id: 'game'      
      },
      {
        label: 'Scrimmage',
        value: 'scrimmage',
        _id: 'scrimmage'        
      },
      {
        label: 'Practice',
        value: 'practice',
        _id: 'practice',       
      },
      {
        label: 'Fundraiser',
        value: 'fundraiser',
        _id: 'fundraiser'       
      },
      {
        label: 'Other',
        value: 'other',
        _id: 'other'        
      }

    ]
}

