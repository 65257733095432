import React, { useState } from "react";

import { Avatar, Card, CardContent, CardHeader, CardMedia, Divider, Stack, Typography, IconButton} from "@mui/material";

import GroupsIcon from '@mui/icons-material/Groups';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { TeamTabs } from "./TeamTabs";


const TeamDetails = (props) => {
   const {team, handleSelectedTeam} = props        

  return (<>
    {team && 
    <Card sx={{pb: 3}}>      
        
      <CardHeader         
        action={
          <IconButton onClick={() => handleSelectedTeam(false)}>
            <KeyboardBackspaceIcon />
          </IconButton>   
        }
        avatar={<Avatar src={`/images/${team.logo}`}/>}
        titleTypographyProps={{variant:'h6' }}
        title={team.fullName}
        subheader={team.sport} 
        sx={{width: '100%'}} 
      />        
            
      <CardContent>
        <Typography variant="body">
          {team.organization?.name}
        </Typography>      
      </CardContent>

      <Divider />
      <TeamTabs team={team} />
    </Card>}   

    </>)
}

export default TeamDetails