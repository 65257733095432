import React, {useContext} from "react";

import {  Card, CardHeader } from '@mui/material'

import { AdminContext } from "context/AdminContext";
import { UserInformation } from "./UserInformation";
import { UserContact } from "./UserContact";
import { UserPlayer } from "./UserPlayer";
import { UserOrgs } from "./UserOrgs";
import { PasswordChange } from "./PasswordChange";
import { ResetPassword } from "./ResetPassword";


const Account = () => {
  const { user } = useContext(AdminContext)   

  return (
    <Card>
      <CardHeader 
        title={`Account | ${user.fullName}`}         
        subheader={`${user.created}. ${user.lastLogin ? ' Last login ' + user.lastLogin : ''}`}          
      />
      <UserInformation />
      <UserContact />
      <UserPlayer />
      <UserOrgs />
      <PasswordChange />
      <ResetPassword />
    </Card>
  )
}

export default Account