import api from "./_api";

class MemberApi {
  get() {
    return api.get('profile')
  }
  
  update(id, data) {
    return api.put(`profile/${id}`, data)
  }

  delete(id) {
    return api.delete(`profile/${id}`)
  }  
}

export default new MemberApi