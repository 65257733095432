import React, { useState } from "react";
import validator from "validator";

import {Stack, Card, Box, CardMedia, CardHeader, CardActions, CardContent, TextField, Link, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import AuthApi from "api/AuthApi";
const logo = require('assets/images/logo.png')


export function ForgotPassword () {  
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState();
  const [msg, setMsg] = useState(false)
  const navigate = useNavigate()

  const handleForm = (e) => {
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })
  }

  const submit = async () => {
    setLoading(true)
    await AuthApi.forgotPassword(formData)
    .then(res => {
        if (res.status === 200) {
          setMsg({severity:'success', msg: res.data.message})
        }
      }
    )
    .catch(err => setMsg({severity:'error', msg: `This email does not exist.`}))
    .finally(() =>  setLoading(false))
  }

  return (
    <Stack sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth:'none',
      minHeight: '100vh',
      width: '100vw',
      background: "linear-gradient(rgba(0,0,0) 60%,rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url('/images/bg-image-two.png')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }}>      
      <Card>          
        <Stack direction={'row'}>
          <CardMedia component={'img'} src={logo} sx={{maxWidth: '50px', height: 'auto'}} alt='Team Manager'/>
          <CardHeader title={'Reset Password'} />
        </Stack>        
        <CardContent>
        <Box 
            component='form'         
            sx={{'& > :not(style)': { m: 1, width: {xs: '25ch', md: '50ch' }},
                display: {xs: 'flex', md:'block'}, flexDirection:'column', alignItems:'center', justifyContent: {xs:'center', md:'center'}
                }}   
            onChange={(e) => handleForm(e)}                     
          >
            <TextField 
              variant="outlined"
              id='email'
              label="Email"
              type='email'
              error={(formData?.email && !validator.isEmail(formData.email))}
            />

          </Box>
        </CardContent>            
        {msg && 
          <Alert 
            severity={msg.severity}
            onClose={() => setMsg(false)}
            >
              {msg.msg}</Alert>}
        <CardActions
        sx={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>
            <LoadingButton
              variant="outlined"
              loading={loading}
              onClick={submit}
              disabled={!(formData?.email && validator.isEmail(formData.email))}
            >
              Submit</LoadingButton>
              <Link
            underline="none"            
            component="button"
            variant="body2"
            onClick={() => {
              navigate('/login')
            }}
            >
              Log in
            </Link>
        </CardActions>
      </Card>

    </Stack>

  )
}